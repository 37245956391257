import { axiosInterceptorsAddHeader, http } from "../API";
import { me } from "../API/AuthService";
import { ORG_USER_ACCESS_TOKEN } from "../lib/consts/consts";

export const privateOrgUserRouteLoader = async () => {
  if (!sessionStorage.getItem(ORG_USER_ACCESS_TOKEN)) {
    console.error("Authorization information does not exist.");
    throw new Error("Authorization information does not exist");
  }

  http.interceptors.request.eject(axiosInterceptorsAddHeader);

  http.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${sessionStorage.getItem(ORG_USER_ACCESS_TOKEN)}`;
    return config;
  });

  return await me();
};
