import React from "react";

import OrgPatientsWidget from "../../components/OrgPatientsWidget";
import styles from "./index.module.css";

const OrgPatientsPage = () => {
  return (
    <div className={styles.container}>
      <OrgPatientsWidget />
    </div>
  );
};

export default OrgPatientsPage;
