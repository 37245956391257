import React, { FC, memo, useEffect } from "react";
import { useInView } from "react-hook-inview";

import { Answer, DetailQuestionnaire } from "../../../lib/models/questionnaire";
import styles from "./index.module.css";

type Props = {
  template: DetailQuestionnaire;
  setSelectedTemplate: (template: DetailQuestionnaire) => void;
};

const answerProcessing = (answer?: Answer) => {
  if (!answer) {
    return "Нет ответа";
  }
  if (answer.skipped) {
    return "Ответ пропущен";
  }
  if (Array.isArray(answer.answer)) {
    return answer.answer.join(", ");
  }
  if (answer.answer) {
    return answer.answer;
  }
  return "Нет ответа";
};

const Template: FC<Props> = ({ template, setSelectedTemplate }) => {
  const [topRef, isTopVisible] = useInView({
    threshold: 0,
  });

  const [bottomRef, isBottomVisible] = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (isTopVisible) {
      setSelectedTemplate(template);
    }
  }, [isTopVisible]);

  useEffect(() => {
    if (isBottomVisible) {
      setSelectedTemplate(template);
    }
  }, [isBottomVisible]);

  return (
    <div id={`template${template.id}`} className={`template  ${styles.form}`}>
      <div ref={topRef}></div>
      <div className={`${styles.form__header}`}>
        <p className={styles.form__header__heading}>{template.name}</p>
      </div>
      <hr />
      {template.sections.map((section, indexSections) => (
        <div
          id={`section${indexSections}template${template.id}`}
          className={`${styles.section}`}
          key={section.name + indexSections}
        >
          <p className={styles.section__title}>{section.name}</p>
          {section.questions.map((question, index) => (
            <>
              <div className={`question ${styles.block}`} key={question.markup.question + index}>
                <div className={`${styles.question}`}>{question.markup.question}</div>
                {question.child_questions?.length ? (
                  <div>
                    {question.child_questions.map((q, index) => (
                      <div key={index}>
                        <p className={styles.subQuestion}>{q.markup.question}</p>
                        <p className={styles.answer}>{answerProcessing(q.answer)}</p>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className={styles.answer}>{answerProcessing(question.answer)}</div>
                )}
              </div>
            </>
          ))}
          <hr />
        </div>
      ))}
      <div ref={bottomRef}></div>
    </div>
  );
};

export default memo(Template);
