import { FC, useEffect } from "react";
import React from "react";

import { ForgotPassword } from "../../components/Login/ForgotPassword";
import { LoginLayout } from "../../components/Login/LoginLayout";
import { hideChat } from "../../lib/utils/hideChat";

export const ForgotPasswordPage: FC = () => {
  useEffect(() => {
    hideChat();
  }, []);
  return (
    <LoginLayout>
      <ForgotPassword />
    </LoginLayout>
  );
};
