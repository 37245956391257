import React from "react";

interface propAnalysis {
  className: string;
  color?: string;
  isCheck?: boolean;
}

const Analysis = (props: propAnalysis) => {
  const { className, color = "#6100FF", isCheck = false } = props;
  return (
    <svg
      className={className}
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1.5" y="1.5" width="61" height="61" rx="30.5" fill={isCheck ? "#6100FF" : "white"} />
      <mask id="mask0_11303_30237" maskUnits="userSpaceOnUse" x="16" y="16" width="32" height="32">
        <rect x="16" y="16" width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_11303_30237)">
        <path
          d="M22.6641 43.9974V41.3307H29.3307V38.6641C27.4863 38.6641 25.9141 38.0141 24.6141 36.7141C23.3141 35.4141 22.6641 33.8418 22.6641 31.9974C22.6641 30.6418 23.0363 29.4085 23.7807 28.2974C24.5252 27.1863 25.5307 26.3752 26.7974 25.8641C26.9752 25.1085 27.3696 24.4974 27.9807 24.0307C28.5918 23.5641 29.2863 23.3307 30.0641 23.3307L29.3307 21.2641L30.5974 20.7974L30.1307 19.5974L32.6641 18.6641L33.0641 19.9307L34.3307 19.4641L37.9974 29.4641L36.7307 29.9307L37.1974 31.1974L34.6641 32.1307L34.2641 30.8641L32.9974 31.3307L32.1974 29.1307C31.8641 29.4418 31.4807 29.6752 31.0474 29.8307C30.6141 29.9863 30.1752 30.0418 29.7307 29.9974C29.2418 29.953 28.7863 29.803 28.3641 29.5474C27.9418 29.2918 27.5752 28.9752 27.2641 28.5974C26.6641 28.953 26.1918 29.4307 25.8474 30.0307C25.503 30.6307 25.3307 31.2863 25.3307 31.9974C25.3307 33.1085 25.7196 34.053 26.4974 34.8307C27.2752 35.6085 28.2196 35.9974 29.3307 35.9974H39.9974V38.6641H33.3307V41.3307H41.3307V43.9974H22.6641ZM34.1974 28.7307L35.3974 28.2641L33.1307 21.9974L31.8641 22.4641L34.1974 28.7307ZM29.9974 27.9974C30.3752 27.9974 30.6918 27.8696 30.9474 27.6141C31.203 27.3585 31.3307 27.0418 31.3307 26.6641C31.3307 26.2863 31.203 25.9696 30.9474 25.7141C30.6918 25.4585 30.3752 25.3307 29.9974 25.3307C29.6196 25.3307 29.303 25.4585 29.0474 25.7141C28.7918 25.9696 28.6641 26.2863 28.6641 26.6641C28.6641 27.0418 28.7918 27.3585 29.0474 27.6141C29.303 27.8696 29.6196 27.9974 29.9974 27.9974Z"
          fill={isCheck ? "white" : color}
        />
      </g>
      <rect x="1.5" y="1.5" width="61" height="61" rx="30.5" stroke={color} strokeWidth="3" />
    </svg>
  );
};

export default Analysis;
