import React from "react";

interface propsTestPaid {
  className: string;
  color?: string;
  isCheck?: boolean;
}
const TestPaid = (props: propsTestPaid) => {
  const { className, color = "#6100FF", isCheck = true } = props;
  return (
    <svg
      width="64"
      className={className}
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1.5" y="1.5" width="61" height="61" rx="30.5" fill={isCheck ? "#6100FF" : "white"} />
      <mask id="mask0_11298_30195" maskUnits="userSpaceOnUse" x="16" y="16" width="32" height="32">
        <rect x="16" y="16" width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_11298_30195)">
        <path
          d="M24.0026 45.3307C23.2693 45.3307 22.6415 45.0696 22.1193 44.5474C21.597 44.0252 21.3359 43.3974 21.3359 42.6641V26.6641C21.3359 25.9307 21.597 25.303 22.1193 24.7807C22.6415 24.2585 23.2693 23.9974 24.0026 23.9974H26.6693C26.6693 22.5307 27.1915 21.2752 28.2359 20.2307C29.2804 19.1863 30.5359 18.6641 32.0026 18.6641C33.4693 18.6641 34.7248 19.1863 35.7693 20.2307C36.8137 21.2752 37.3359 22.5307 37.3359 23.9974H40.0026C40.7359 23.9974 41.3637 24.2585 41.8859 24.7807C42.4082 25.303 42.6693 25.9307 42.6693 26.6641V42.6641C42.6693 43.3974 42.4082 44.0252 41.8859 44.5474C41.3637 45.0696 40.7359 45.3307 40.0026 45.3307H24.0026ZM29.3359 23.9974H34.6693C34.6693 23.2641 34.4082 22.6363 33.8859 22.1141C33.3637 21.5918 32.7359 21.3307 32.0026 21.3307C31.2693 21.3307 30.6415 21.5918 30.1193 22.1141C29.597 22.6363 29.3359 23.2641 29.3359 23.9974ZM36.0026 30.6641C36.3804 30.6641 36.6971 30.5363 36.9526 30.2807C37.2082 30.0252 37.3359 29.7085 37.3359 29.3307V26.6641H34.6693V29.3307C34.6693 29.7085 34.797 30.0252 35.0526 30.2807C35.3082 30.5363 35.6248 30.6641 36.0026 30.6641ZM28.0026 30.6641C28.3804 30.6641 28.697 30.5363 28.9526 30.2807C29.2082 30.0252 29.3359 29.7085 29.3359 29.3307V26.6641H26.6693V29.3307C26.6693 29.7085 26.797 30.0252 27.0526 30.2807C27.3082 30.5363 27.6248 30.6641 28.0026 30.6641Z"
          fill={isCheck ? "white" : color}
        />
      </g>
      <rect x="1.5" y="1.5" width="61" height="61" rx="30.5" stroke={color} strokeWidth="3" />
    </svg>
  );
};

export default TestPaid;
