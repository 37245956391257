import { useMutation, useQuery, useQueryClient, UseQueryOptions } from "react-query";

import { createOrgUser, deleteOrgUser, getOrgUsers } from "../../API/OrgService";
import { OrgUser } from "../../lib/models/OrgUser";

export const useFetchOrgUsers = (args?: { options?: UseQueryOptions<OrgUser[], Error> }) => {
  const { data, ...rest } = useQuery<OrgUser[], Error>(
    ["users", "list"],
    async () => {
      const users = await getOrgUsers();
      return users.filter((user) => user.test);
    },
    { ...args?.options }
  );

  return { ...rest, data: data || [] };
};

export const useCreateOrgUser = (args?: { onSuccess?: () => void }) => {
  const queryClient = useQueryClient();

  return useMutation(createOrgUser, {
    onSettled: async () => {
      await queryClient.invalidateQueries(["users"]);
    },
    onSuccess: () => {
      args?.onSuccess?.();
    },
  });
};

export const useDeleteOrgUser = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteOrgUser, {
    onSettled: async () => {
      await queryClient.invalidateQueries(["users"]);
    },
  });
};
