import React, { FC, useEffect, useState } from "react";

import { ReactComponent as SearchIcon } from "../../images/searchIcon.svg";
import { Input } from "../../UI/Input";
import styles from "./SearchBar.module.css";

type Props = {
  setValue: (value: string) => void;
};

export const SearchBar: FC<Props> = ({ setValue: setPropsValue }) => {
  const [value, setValue] = useState<string>("");

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPropsValue(value);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [value]);

  return (
    <div className={styles.container}>
      <h2>Выбор клиента</h2>
      <div className={styles.searchBarWrapper}>
        <Input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className={styles.searchBarInput}
          placeholder="Поиск"
        />
        <div className={styles.searchIcon}>
          <SearchIcon />
        </div>
      </div>
    </div>
  );
};
