import { FC, useState } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import { publicHttp } from "../../../API";
import arrow from "../../../images/arrow.svg";
import { GlobalUrls } from "../../../lib/routes";
import { Button } from "../../../UI/Button";
import { Input } from "../../../UI/Input";
import { FinishScreen } from "../FinishScreen";
import styles from "./index.module.css";

export const ForgotPassword: FC = () => {
  const [toggle, setToggle] = useState(1);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });
  const onSubmit = async (data: any) => {
    try {
      const response = await publicHttp.post("/auth/users/reset_password/", {
        email: data.email.toLowerCase(),
      });
      setError("");
      setToggle(2);
      setEmail(data.email.toLowerCase());
    } catch (e: any) {
      console.log(e);
      setError(e.response.data.detail);
    }
  };

  return (
    <>
      {toggle === 1 && (
        <div className={styles.container}>
          <div className={styles.goBack}>
            <img src={arrow} />
            <Link to={GlobalUrls.LOGIN}>Вернуться ко входу</Link>
          </div>
          <h2 className={styles.header}>Восстановление пароля</h2>
          <h3 className={styles.emailHeader}>Введите ваш Email адрес</h3>
          <p className={styles.secondaryText}>Мы отправим вам на почту код для восстановления пароля</p>
          <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <Input
              type="email"
              placeholder="Email адрес"
              register={register("email", { required: true })}
              error={error}
            />
            <Button variant="primary" type="submit" size="large" disabled={!isValid}>
              Далее
            </Button>
          </form>
        </div>
      )}
      {toggle === 2 && (
        <FinishScreen
          header="Всё получилось!"
          primaryText={`Чтобы сбросить пароль, следуйте инструкциям из письма, отправленного на адрес ${email}`}
          secondaryText="Если вы не получили письмо, проверьте папку «Спам» или попробуйте"
          linkText="отправить письмо еще раз"
          onClick={() => setToggle(1)}
        />
      )}
    </>
  );
};
