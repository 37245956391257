import React from "react";

import { useFetchInterpretation, usePatchInterpretation } from "../../../queries/interpretation";
import { usePatientStore } from "../../../store/usePatientStore";
import { Button } from "../../../UI/Button";
import DocInterpretationDropdown from "../DocInterpretationDropdown/DocInterpretationDropdown";
import styles from "./DocInterpretationDropdownList.module.css";
type Props = {
  onUpdateButton?: () => void;
};
const DocInterpretationDropdownList = ({ onUpdateButton }: Props) => {
  const { mainTest } = usePatientStore((state) => state);
  if (!mainTest) throw new Error("Main test not found");
  const { data } = useFetchInterpretation(mainTest.id);
  const { mutateAsync: patchInterpretation } = usePatchInterpretation();
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <p>
          Заполнено {data.filter((e) => e.value.length).length}/{data.length}
        </p>
        <Button
          onClick={() => {
            onUpdateButton && onUpdateButton();
          }}
          className={styles.button}
          variant="white"
          size="small"
        >
          Обновить отчет
        </Button>
      </div>
      {data.map((e, index) => (
        <DocInterpretationDropdown
          key={index}
          name={e.name}
          initValue={e.value}
          onSubmit={async (value) => {
            await patchInterpretation({
              testId: mainTest.id,
              interpretationFields: data.map((e, i) => ({ ...e, value: i === index ? value : e.value })),
            });
          }}
        />
      ))}
    </div>
  );
};

export default DocInterpretationDropdownList;
