import { FC } from "react";
import React from "react";
import { Link } from "react-router-dom";

import arrow from "../../../images/arrow.svg";
import { WarningIcon } from "../../../images/warning";
import { GlobalUrls } from "../../../lib/routes";
import styles from "./index.module.css";

type Props = {
  header: string;
  primaryText: string;
  onClick: any;
  linkText: string;
  secondaryText: string;
};

export const FinishScreen: FC<Props> = ({ primaryText, secondaryText, header, onClick, linkText }) => {
  return (
    <div className={styles.container}>
      <Link to={GlobalUrls.LOGIN} className={styles.goBack}>
        <img src={arrow} />
        Вернуться ко входу
      </Link>
      <h2 className={styles.header}>{header}</h2>
      <p className={styles.primaryText}>{primaryText}</p>
      <div className={styles.warningCard}>
        <WarningIcon />
        <div>
          <p className={styles.warningText}>{secondaryText}</p>
          <span className={styles.linkText} onClick={onClick}>
            {linkText}
          </span>
        </div>
      </div>
    </div>
  );
};
