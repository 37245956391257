import { FC, useEffect } from "react";
import React from "react";
import { Navigate, useParams } from "react-router-dom";

import { publicHttp } from "../../API";

export const ConfirmEmailEmptyPage: FC = () => {
  const { UID, token } = useParams();
  const data = { uid: UID, token: token };

  const confirmEmail = async () => {
    try {
      const response = publicHttp.post("/auth/users/activation/", data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    confirmEmail();
  }, []);
  return <div>{<Navigate to="/" replace={true} />}</div>;
};
