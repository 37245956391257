import React, { useEffect, useMemo, useState } from "react";
import { PropagateLoader } from "react-spinners";

import { PrimaryColors } from "../../lib/consts/colors";
import { useFetchOrgUsers } from "../../queries/org/users";
import { useOrgStore } from "../../store/useOrgStore";
import EmptyDataBlock from "../EmptyDataBlock";
import AddOrgPatientSection from "./AddOrgPatientSection";
import styles from "./index.module.css";
import OrgPatientsTable from "./OrgPatientsTable";
import { SearchBar } from "./SearchBar";

const OrgPatientsWidget = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const { selectedOrgUser, setSelectedOrgUser } = useOrgStore((state) => state);

  const { data, isLoading } = useFetchOrgUsers();

  useEffect(() => {
    if (selectedOrgUser) {
      if (!(data || []).includes(selectedOrgUser)) {
        setSelectedOrgUser(null);
      }
    }
  }, [data, selectedOrgUser]);

  const orgUsersFilteredBySearchValue = useMemo(() => {
    if (!searchValue || !data) {
      return data;
    }
    return data.filter((e) => {
      const searchChain = [e.first_name, e.middle_name, e.last_name, e.test.serial_number].join(" ").toLowerCase();
      return searchChain.includes(searchValue.toLowerCase());
    });
  }, [searchValue, data]);

  const render = () => {
    if (isLoading && !data) {
      return (
        <div className={styles.noUsersBlock}>
          <PropagateLoader color={PrimaryColors._900} />
        </div>
      );
    } else {
      if (data.length) {
        if (orgUsersFilteredBySearchValue?.length) {
          return (
            <div>
              <OrgPatientsTable patients={orgUsersFilteredBySearchValue.slice().reverse()} />
            </div>
          );
        } else {
          return (
            <EmptyDataBlock>
              <h2>Клиент не найден</h2>
            </EmptyDataBlock>
          );
        }
      } else {
        return (
          <EmptyDataBlock>
            <h2>
              Здесь пока нет пользователей,
              <br />
              но вы можете добавить их вручную
            </h2>
          </EmptyDataBlock>
        );
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.searchBarSection}>
        <SearchBar setValue={setSearchValue} />
      </div>
      <div className={styles.tableSection}>{render()}</div>
      <div className={styles.addPatientSection}>
        <AddOrgPatientSection />
      </div>
    </div>
  );
};

export default OrgPatientsWidget;
