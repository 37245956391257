import React, { FC, InputHTMLAttributes } from "react";

import styles from "./index.module.css";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  variant: "checkbox" | "radio";
  children: React.ReactNode;
  register?: any;
  labelClassName?: string;
  error?: string | boolean;
  errorClassName?: string;
}

const Checkbox: FC<Props> = ({ variant, children, register, labelClassName, error, errorClassName, ...rest }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }} className={`${error && styles.errorState}`}>
      <label className={`${styles.checkBox} ${labelClassName}`}>
        <div className={styles.text}>{children}</div>
        <input type={variant} {...rest} {...register} />
        {variant === "checkbox" ? (
          <span className={styles.checkboxmark} />
        ) : (
          <span className={styles.radiomark}>
            <span className={styles.circle}></span>
          </span>
        )}
      </label>
      {error && (
        <div className={`${typeof error === "string" ? styles.errorMessage : ""} ${errorClassName}`}>
          {error === "required" ? "" : error}
        </div>
      )}
    </div>
  );
};

export default Checkbox;
