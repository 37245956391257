import React, { FC, useEffect } from "react";

import { LoginForm } from "../../components/Login/LoginForm";
import { LoginLayout } from "../../components/Login/LoginLayout";
import { hideChat } from "../../lib/utils/hideChat";

export const LoginPage: FC = () => {
  useEffect(() => {
    hideChat();
  }, []);
  return (
    <>
      <LoginLayout>
        <LoginForm />
      </LoginLayout>
    </>
  );
};
