import React from "react";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";

import { publicHttp } from "../../../API";
import { Button } from "../../../UI/Button";
import { Input } from "../../../UI/Input";
import styles from "./index.module.css";

type Props = {
  UID: string | undefined;
  token: string | undefined;
};

export const ResetPassword: FC<Props> = ({ UID, token }) => {
  const [error, setError] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });
  const onSubmit = async (data: any) => {
    try {
      await publicHttp.post("/auth/users/reset_password_confirm/", {
        ...data,
        uid: UID,
        token: token,
      });
      setError(false);
      setRedirect(true);
    } catch (e) {
      console.log(e);
      setError(true);
    }
  };

  return (
    <div className={styles.container}>
      {redirect && <Navigate to="/" replace={true} />}
      <h3 className={styles.header}>Восстановление пароля</h3>
      <h4 className={styles.secondaryHeader}>Введите ваш новый пароль</h4>
      <p className={styles.secondaryText}>Создайте новый надежный пароль для вашей учетной записи</p>

      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <Input
          type="password"
          placeholder="Пароль"
          register={register("new_password", { required: true })}
          className={error ? styles.inputError : styles.input}
        />
        <Input
          type="password"
          placeholder="Подтвердите пароль"
          register={register("re_new_password", { required: true })}
          className={error ? styles.inputError : styles.input}
        />

        {error && <p className={styles.error}>Пароли не совпадают</p>}

        <Button variant="primary" size="large" type="submit" disabled={!isValid}>
          Отправить
        </Button>
      </form>
    </div>
  );
};
