import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PropagateLoader } from "react-spinners";

import { PrimaryColors } from "../../lib/consts/colors";
import { OrgPatientUrls } from "../../lib/routes";
import { useOrgStore } from "../../store/useOrgStore";
import EmptyDataBlock from "../EmptyDataBlock";
import styles from "./index.module.css";
import OrgPatientIframe from "./OrgPatientIframe";

const OrgPatientContentWidget = () => {
  const location = useLocation();
  const [startLocation, setStartLocation] = useState<string>(location.pathname);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const { selectedOrgUser } = useOrgStore((state) => state);
  const [iframeIsLoading, setIframeIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const iframeGoToSendMessage = useCallback((pathname: string) => {
    const orgPatientUrls = Object.values(OrgPatientUrls).map((e) => String(e));
    if (orgPatientUrls.includes(pathname)) {
      iframeRef.current?.contentWindow?.postMessage("goto:" + "/iframe" + pathname, "*");
    }
  }, []);

  useEffect(() => {
    iframeGoToSendMessage(location.pathname);
  }, [location]);

  useEffect(() => {
    return () => {
      setIframeIsLoading(true);
    };
  }, []);

  const render = () => {
    if (selectedOrgUser) {
      if (!selectedOrgUser.test.report_ready && location.pathname === String(OrgPatientUrls.REPORT)) {
        return (
          <div className={styles.container}>
            <EmptyDataBlock
              showShadow={true}
              buttonOptions={{
                onClick: () => {
                  setStartLocation(String(OrgPatientUrls.QUESTIONNAIRES));
                  navigate(OrgPatientUrls.QUESTIONNAIRES);
                },
                caption: "Заполнить анкету",
              }}
            >
              <h2 className={styles.caption}>
                Пока биоматериал анализируется,
                <br /> предлагаем заполнить анкету
                <br /> в <span>Личном кабинете</span>
              </h2>
            </EmptyDataBlock>
          </div>
        );
      } else {
        return (
          <>
            {iframeIsLoading && (
              <div className={styles.container}>
                <PropagateLoader color={PrimaryColors._900} />
              </div>
            )}
            <OrgPatientIframe
              iframeIsLoading={iframeIsLoading}
              setIframeIsLoading={setIframeIsLoading}
              startLocation={startLocation}
              ref={iframeRef}
              onLoad={() =>
                setTimeout(() => {
                  setIframeIsLoading(false);
                  iframeGoToSendMessage(location.pathname);
                }, 1000)
              }
            />
          </>
        );
      }
    } else {
      return (
        <div className={styles.container}>
          <EmptyDataBlock showShadow={true}>
            <h2 className={styles.caption}>Клиент не выбран</h2>
          </EmptyDataBlock>
        </div>
      );
    }
  };

  return <>{render()}</>;
};

export default OrgPatientContentWidget;
