import React from "react";

import styles from "./index.module.css";
interface chevronType {
  color?: string;
  width?: number;
  height?: number;
  viewBox?: string;
  turn?: number;
}
const Chevron = (props: chevronType) => {
  const { color = "#3E5BEF", width = 12, viewBox = "0 0 7 12", height = 7, turn = 0 } = props;
  return (
    <div className={styles.container}>
      <svg
        width={width}
        height={height}
        viewBox={viewBox}
        style={{ transform: `rotate(${turn}deg)`, transition: "transform 0.5s" }}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7 10.6L5.67568 12L-2.62268e-07 6L5.67568 -2.48092e-07L7 1.4L2.64865 6L7 10.6Z" fill={`${color}`} />
      </svg>
    </div>
  );
};

export default Chevron;
