import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";

import { Aside } from "../../components/Aside";
import { AsideMobile } from "../../components/AsideMobile";
import AsideProvider from "../../generic/providers/AsideProvider";
import { useScreenType } from "../../lib/hooks/useScreenType";
import styles from "./index.module.css";

const UserLayout = () => {
  const screenType = useScreenType();

  if (screenType === "mobile") {
    return (
      <AsideProvider>
        <div className={styles.mobileContainer}>
          <header>
            <AsideMobile />
          </header>
          <main>
            <Suspense fallback={<div />}>
              <Outlet />
            </Suspense>
          </main>
        </div>
      </AsideProvider>
    );
  }

  return (
    <AsideProvider>
      <div className={styles.container}>
        <div className={styles.aside}>
          <Aside />
        </div>
        <main>
          <Suspense fallback={<div />}>
            <Outlet />
          </Suspense>
        </main>
      </div>
    </AsideProvider>
  );
};

export default UserLayout;
