import { AxiosError, isAxiosError } from "axios";

import { CreateOrgUser, OrgUser } from "../../lib/models/OrgUser";
import { http } from "../index";

export const getOrgUsers = async () => {
  const response = await http.get<OrgUser[]>("/api/org/users/");
  return response.data;
};

export const createOrgUser = async (data: CreateOrgUser) => {
  try {
    const response = await http.post<OrgUser>("/api/org/users/", data);
    return response.data;
  } catch (e) {
    const error = e as Error | AxiosError;
    if (!isAxiosError(error)) {
      throw new Error("Неизвестная ошибка");
    }
    switch (error.response?.data.test_id.at(0)) {
      case "No Test with given serial_number":
        throw new Error("Тест с таким ID не найден");
      case "Test was already bind":
        throw new Error("Тест уже привязан");
      default:
        throw new Error("Неизвестная ошибка");
    }
  }
};

export const updateOrgUser = async (id: number, data: Omit<OrgUser, "pk">) => {
  const response = await http.patch<OrgUser>(`/api/org/users/${id}/`, data);
  return response.data;
};

export const deleteOrgUser = async (id: number) => {
  const response = await http.delete<unknown>(`api/org/users/${id}/`);
  return response.data;
};
