import React from "react";
interface propsStep {
  Img?: string;
  TextTitle?: any;
  Button?: any;
  TextSmall?: any;
}
import styles from "./index.module.css";
const ComponentStep = (props: propsStep) => {
  const { Img, TextTitle, Button, TextSmall } = props;
  return (
    <div className={styles.content}>
      <img className={styles.img} src={Img && Img} />
      <div className={styles.testTitle}>{TextTitle && TextTitle}</div>
      <div className={styles.textSmall}>{TextSmall && TextSmall}</div>
      {Button && Button}
    </div>
  );
};

export default ComponentStep;
