import React from "react";

interface propsOnRoad {
  className: string;
  color?: string;
  isCheck?: boolean;
}
const OnRoad = (props: propsOnRoad) => {
  const { className, color = "#6100FF", isCheck = false } = props;
  return (
    <svg
      className={className}
      width="64"
      height="65"
      viewBox="0 0 64 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1.5" y="2" width="61" height="61" rx="30.5" fill={isCheck ? "#6100FF" : "white"} />
      <mask id="mask0_11303_30223" maskUnits="userSpaceOnUse" x="16" y="16" width="32" height="33">
        <rect x="16" y="16.5" width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_11303_30223)">
        <path
          d="M24.0026 43.1693C22.8915 43.1693 21.947 42.7804 21.1693 42.0026C20.3915 41.2248 20.0026 40.2804 20.0026 39.1693H17.3359V24.5026C17.3359 23.7693 17.597 23.1415 18.1193 22.6193C18.6415 22.097 19.2693 21.8359 20.0026 21.8359H38.6693V27.1693H42.6693L46.6693 32.5026V39.1693H44.0026C44.0026 40.2804 43.6137 41.2248 42.8359 42.0026C42.0582 42.7804 41.1137 43.1693 40.0026 43.1693C38.8915 43.1693 37.9471 42.7804 37.1693 42.0026C36.3915 41.2248 36.0026 40.2804 36.0026 39.1693H28.0026C28.0026 40.2804 27.6137 41.2248 26.8359 42.0026C26.0582 42.7804 25.1137 43.1693 24.0026 43.1693ZM24.0026 40.5026C24.3804 40.5026 24.697 40.3748 24.9526 40.1193C25.2082 39.8637 25.3359 39.547 25.3359 39.1693C25.3359 38.7915 25.2082 38.4748 24.9526 38.2193C24.697 37.9637 24.3804 37.8359 24.0026 37.8359C23.6248 37.8359 23.3082 37.9637 23.0526 38.2193C22.797 38.4748 22.6693 38.7915 22.6693 39.1693C22.6693 39.547 22.797 39.8637 23.0526 40.1193C23.3082 40.3748 23.6248 40.5026 24.0026 40.5026ZM20.0026 36.5026H21.0693C21.447 36.1026 21.8804 35.7804 22.3693 35.5359C22.8582 35.2915 23.4026 35.1693 24.0026 35.1693C24.6026 35.1693 25.147 35.2915 25.6359 35.5359C26.1248 35.7804 26.5582 36.1026 26.9359 36.5026H36.0026V24.5026H20.0026V36.5026ZM40.0026 40.5026C40.3804 40.5026 40.697 40.3748 40.9526 40.1193C41.2082 39.8637 41.3359 39.547 41.3359 39.1693C41.3359 38.7915 41.2082 38.4748 40.9526 38.2193C40.697 37.9637 40.3804 37.8359 40.0026 37.8359C39.6248 37.8359 39.3082 37.9637 39.0526 38.2193C38.7971 38.4748 38.6693 38.7915 38.6693 39.1693C38.6693 39.547 38.7971 39.8637 39.0526 40.1193C39.3082 40.3748 39.6248 40.5026 40.0026 40.5026ZM38.6693 33.8359H44.3359L41.3359 29.8359H38.6693V33.8359Z"
          fill={isCheck ? "white" : color}
        />
      </g>
      <rect x="1.5" y="2" width="61" height="61" rx="30.5" stroke={color} strokeWidth="3" />
    </svg>
  );
};

export default OnRoad;
