import { isAxiosError } from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Link } from "react-router-dom";

import { publicHttp } from "../../../API";
import arrow from "../../../images/arrow.svg";
import { GlobalUrls } from "../../../lib/routes";
import { useRightBottomPopup } from "../../../store/useRightBottomPopup";
import { Button } from "../../../UI/Button";
import Checkbox from "../../../UI/Checkbox/index.";
import { Input } from "../../../UI/Input";
import { FinishScreen } from "../FinishScreen";
import styles from "./index.module.css";

interface SignInFormType {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  password: string;
  docs: boolean;
}

export const SignInForm = () => {
  const [toggle, setToggle] = useState(1);
  const { openPopup } = useRightBottomPopup((state) => state);
  const {
    control,
    register,
    watch,
    handleSubmit,
    setError,
    clearErrors,
    formState: { isValid, errors, isSubmitting },
  } = useForm<SignInFormType>({ mode: "onChange" });
  const { email } = useWatch({ control });
  const onSubmit = async (data: SignInFormType) => {
    try {
      await publicHttp.post("/auth/users/", {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        password: data.password,
        re_password: data.password,
        phone_number: data.phone_number,
      });
      setToggle(2);
    } catch (e) {
      if (isAxiosError(e)) {
        if (e.response && e.response.status === 400) {
          for (const [fieldName, errorMessage] of Object.entries(e.response.data)) {
            const message = String(errorMessage).endsWith(".")
              ? String(errorMessage).slice(0, -1)
              : String(errorMessage);
            // @ts-expect-error untyped field from backend
            setError(fieldName, {
              type: "value",
              message,
            });
          }
        } else {
          setError("root", { type: "value", message: "Что-то пошло не так" });
        }
      }
    }
  };

  useEffect(() => {
    const subscription = watch(() => {
      clearErrors();
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const retryEmail = async () => {
    const data = { email };
    try {
      await publicHttp.post("/auth/users/resend_activation/", data);
      openPopup("Ссылка отправлена повторно");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {toggle === 1 && (
        <div className={styles.container}>
          <Link to={GlobalUrls.LOGIN} className={styles.goBack}>
            <img src={arrow} />
            <div>Вернуться ко входу</div>
          </Link>
          <h2 className={styles.header}>Создание аккаунта</h2>
          <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <div className={styles.nameWrapper}>
              <Input
                error={errors.first_name?.message}
                type="text"
                placeholder="Ваше имя"
                register={register("first_name", { required: "Обязательное поле" })}
              />
              <Input
                error={errors.last_name?.message}
                type="text"
                placeholder="Ваша фамилия"
                register={register("last_name", { required: "Обязательное поле" })}
              />
            </div>
            <Input
              error={errors.email?.message}
              type="email"
              placeholder="Ваш Email адрес"
              register={register("email", { required: "Обязательное поле" })}
            />
            <Input
              error={errors.phone_number?.message}
              type="number"
              placeholder="Номер телефона"
              register={register("phone_number", { required: "Обязательное поле" })}
            />
            <Input
              error={errors.password?.message}
              type="password"
              placeholder="Пароль"
              register={register("password", { required: "Обязательное поле" })}
            />
            <Checkbox
              error={errors.docs?.message}
              variant="checkbox"
              register={register("docs", { required: "Обязательное поле" })}
              labelClassName={styles.checkbox}
            >
              <div className={styles.approve}>
                Я прочитал(-а) и согласен(-на) с{" "}
                <a
                  href="https://docs.google.com/document/d/1zKFkil2Sz4d5WoEGhYd3U4zAPnPmrIQV/edit?usp=sharing&ouid=100903122716085612305&rtpof=true&sd=true"
                  target="_blank"
                  rel="noreferrer"
                >
                  Политикой обработки персональных данных
                </a>{" "}
                |{" "}
                <a
                  href="https://docs.google.com/document/d/1E_gUfyHnMNLt1KNaWsgg0H2Tf4GK85Ch/edit?usp=sharing&ouid=100903122716085612305&rtpof=true&sd=true"
                  target="_blank"
                  rel="noreferrer"
                >
                  Договором оказания платных информационных услуг
                </a>{" "}
                |{" "}
                <a
                  href="https://docs.google.com/document/d/16E3TqbhSshRWkC-1cn8B7wdJ2pwLvEQk/edit?usp=sharing&ouid=100903122716085612305&rtpof=true&sd=true"
                  target="_blank"
                  rel="noreferrer"
                >
                  Лицензионным договором
                </a>{" "}
                |{" "}
                <a
                  href="https://docs.google.com/document/d/1UqqB96PMl0CrYcNUIK5m1F8UKwbneh6D/edit?usp=sharing&ouid=100903122716085612305&rtpof=true&sd=true"
                  target="_blank"
                  rel="noreferrer"
                >
                  Согласием на обработку персональных данных гражданина
                </a>
              </div>
            </Checkbox>
            <div className={styles.errorMessage}>{errors.root?.message}</div>
            <Button variant="primary" type="submit" size="large" disabled={!isValid || isSubmitting}>
              Далее
            </Button>
          </form>
        </div>
      )}
      {toggle === 2 && (
        <FinishScreen
          header="Спасибо за регистрацию!"
          primaryText={`Чтобы подтвердить регистрацию, следуйте инструкциям из письма, отправленного на адрес ${email}`}
          secondaryText="Если вы не получили письмо, проверьте папку «Спам» или попробуйте"
          linkText="отправить письмо еще раз"
          onClick={retryEmail}
        />
      )}
    </>
  );
};
