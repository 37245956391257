import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { PropagateLoader } from "react-spinners";

import { Logo } from "../../images/logo";
import { useAuth } from "../../layouts/AuthProvider/AuthProvider";
import { PrimaryColors } from "../../lib/consts/colors";
import { ACCESS_TOKEN, TG_BOT_URL } from "../../lib/consts/consts";
import { Button } from "../../UI/Button";
import styles from "./index.module.css";

const Page = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const { user } = useAuth();

  const authorizeTgUser = async () => {
    try {
      setIsLoading(true);
      if (!user) {
        searchParams.set("redirect_url", location.pathname);
        navigate("/login?" + searchParams.toString());
      }
      const tg = searchParams.get("tg");
      const callbacklUrl = searchParams.get("callback_url");
      if (!tg || !callbacklUrl) return;
      console.log(user);
      await axios.post(callbacklUrl, {
        user_id: tg,
        access_token: localStorage.getItem(ACCESS_TOKEN),
      });
      setSuccess(true);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    authorizeTgUser();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.loadingContainer}>
        {isLoading && !success && (
          <div>
            <PropagateLoader color={PrimaryColors._900} />
          </div>
        )}
      </div>
      {success && (
        <div className={styles.successContainer}>
          <header>
            <Link to="/" className={styles.headerLink}>
              <Logo />
            </Link>
          </header>
          <main className={styles.mainContent}>
            <div className={styles.textContent}>
              <h1 className={styles.heading}>Вы успешно авторизовались!</h1>
              <p className={styles.paragraph}>Теперь вы можете вернуться в Telegram-бота виртуального нутрициолога.</p>
              <Button variant="primary" size="large" onClick={() => window.open(TG_BOT_URL || "")}>
                Перейти в бота
              </Button>
            </div>
          </main>
        </div>
      )}
    </div>
  );
};

export default Page;
