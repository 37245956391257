import React, { FC, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getTestResultPdfFile } from "../../API/PatientService";
import { ReactComponent as CrossIcon } from "../../images/cross.svg";
import { ReactComponent as DownloadIcon } from "../../images/downloadIcon.svg";
import { useUser } from "../../lib/hooks/useUser";
import { OrgUser } from "../../lib/models/OrgUser";
import { OrgPatientUrls } from "../../lib/routes";
import { useDeleteOrgUser } from "../../queries/org/users";
import { useOrgStore } from "../../store/useOrgStore";
import { Button } from "../../UI/Button";
import SubmitActionPopup from "../../UI/SubmitActionPopup";
import styles from "./OrgPatientTableRow.module.css";

type Props = {
  orgUser: OrgUser;
};

const OrgPatientTableRow: FC<Props> = ({ orgUser }) => {
  const { test, first_name, last_name, middle_name, id } = orgUser;
  const { mutateAsync } = useDeleteOrgUser();
  const [submitDeletePopupIsOpen, setSubmitDeletePopupIsOpen] = useState<boolean>(false);
  const { setSelectedOrgUser } = useOrgStore((state) => state);
  const handleDeleteButton = useCallback(async () => {
    await mutateAsync(id);
  }, [id]);
  const { user } = useUser();

  const navigate = useNavigate();

  const onDownloadClick = useCallback(async () => {
    const response = await getTestResultPdfFile(orgUser.test.id);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const tempLink = document.createElement("a");
    tempLink.href = url;
    tempLink.setAttribute("download", "results.pdf");
    tempLink.click();
  }, [orgUser.test.id]);

  return (
    <>
      <SubmitActionPopup
        isOpen={submitDeletePopupIsOpen}
        setIsOpen={setSubmitDeletePopupIsOpen}
        onSubmit={handleDeleteButton}
      >
        Вы точно хотите удалить пользователя?
      </SubmitActionPopup>
      <tr className={styles.container}>
        <td>{test.date ? test.date.split("-").reverse().join(".") : "–"}</td>
        <td>{test.serial_number}</td>
        <td>
          <div>
            <p>{[last_name, first_name, middle_name].join(" ")}</p>
            {user.first_name !== orgUser.specialist ? (
              <p className={styles.specialist}>Врач: {orgUser.specialist}</p>
            ) : null}
          </div>
        </td>
        <td>
          <div className={styles.downloadWrapper}>
            <div
              className={`${styles.downloadButtonStatus} ${test.report_ready ? styles.downloadButtonStatusSuccess : styles.downloadButtonStatusDanger}`}
            />
            <Button
              variant="primary"
              size="small"
              type="button"
              disabled={!test.report_ready}
              className={styles.downloadButton}
              onClick={onDownloadClick}
              rightIcon={<DownloadIcon style={{ marginLeft: "-5px" }} />}
            >
              Скачать
            </Button>
          </div>
        </td>
        <td align="center">
          <div className={styles.connectButtonWrapper}>
            <Button
              onClick={() => {
                setSelectedOrgUser(orgUser);
                navigate(OrgPatientUrls.QUESTIONNAIRES);
              }}
              variant="light"
              size="small"
              type="button"
              className={styles.connectButton}
            >
              Переключиться
            </Button>
            <button onClick={() => setSubmitDeletePopupIsOpen(true)} className={styles.deleteButton}>
              <CrossIcon />
            </button>
          </div>
        </td>
      </tr>
    </>
  );
};

export default OrgPatientTableRow;
