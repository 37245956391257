import React, { useEffect, useState } from "react";

import PrintPortal from "../../components/PrintPortal";
import ProgressAside from "../../features/questionnaireReview/ProgressAside";
import TemplatesList from "../../features/questionnaireReview/TemplatesList";
import { useUser } from "../../lib/hooks/useUser";
import { DetailQuestionnaire } from "../../lib/models/questionnaire";
import { useFetchPatientQuestionnaire } from "../../queries/questionnaire";
import styles from "./index.module.css";

const QuestionnaireReviewPage = () => {
  const { user } = useUser();
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState<DetailQuestionnaire | null>(null);
  const { data: detailQuestionnaires } = useFetchPatientQuestionnaire(user.id);

  useEffect(() => {
    document.title = `Анкета - ${user.first_name} ${
      user.last_name && user.last_name
    } ${new Date().toLocaleDateString("ru-RU")} ${new Date().toTimeString().split(" ").at(0)?.replaceAll(":", "-")}
    `;
    return () => {
      document.title = "Novabiom";
    };
  }, [user]);

  useEffect(() => {
    if (!selectedQuestionnaire && detailQuestionnaires[0]) {
      setSelectedQuestionnaire(detailQuestionnaires[0]);
    }
  }, [detailQuestionnaires]);

  return (
    <div className={`${styles.container}`}>
      {selectedQuestionnaire && (
        <>
          <PrintPortal>
            <div className={`${styles.wrapTemplates}`}>
              <TemplatesList setSelectedTemplate={setSelectedQuestionnaire} templates={detailQuestionnaires} />
            </div>
          </PrintPortal>
          <div className={`${styles.wrapTemplates}`}>
            <TemplatesList setSelectedTemplate={setSelectedQuestionnaire} templates={detailQuestionnaires} />
          </div>
          <div>
            <div className={styles.buttonContainer}>
              <button
                onClick={() => {
                  window.print();
                }}
              >
                Сохранить в PDF
              </button>
            </div>
            <ProgressAside
              templates={detailQuestionnaires}
              setSelectedTemplate={setSelectedQuestionnaire}
              selectedTemplate={selectedQuestionnaire}
              activeBlock={0}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default QuestionnaireReviewPage;
