import { FC, useEffect, useState } from "react";
import React from "react";
import { useParams } from "react-router-dom";

import { http } from "../../API";

export const ConfirmDeleteEmptyPage: FC = () => {
  const { UID, token } = useParams();
  const data = { uid: UID, token: token };
  const [status, setStatus] = useState(false);
  const [text, setText] = useState("");

  const confirmEmail = async () => {
    try {
      const response = http.post("/api/users/confirm_deletion", data);
      localStorage.clear();
      if ((await response).status === 204) {
        setStatus(true);
        setText("Аккаунт успешно удалён");
      }
    } catch (e) {
      console.log(e);
      setStatus(false);
      setText("Произошла ошибка");
    }
  };

  useEffect(() => {
    confirmEmail();
  }, []);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      {text}
    </div>
  );
};
