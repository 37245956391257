import React, { FC } from "react";

import { Question } from "../../../lib/models/questionnaire";
import SectionPassingProvider from "./SectionPassingProvider";

type RequiredQuestionsPassingProps = {
  requiredQuestions: Question[];
  progress: number;
  onSectionReadyButton: () => boolean;
};
const RequiredQuestionsPassing: FC<RequiredQuestionsPassingProps> = ({
  requiredQuestions,
  onSectionReadyButton,
  progress,
}) => {
  return (
    <SectionPassingProvider
      section={{
        name: "Обязательные вопросы",
        available_questions_count: requiredQuestions.length,
        id: -1,
        progress,
        questionnaire_template: { id: -1, name: "" },
      }}
      questions={requiredQuestions}
      requiredVersion={true}
      sectionReadyButtonIsActive={progress === 1}
      onSectionReadyButton={onSectionReadyButton}
    />
  );
};

export default RequiredQuestionsPassing;
