import React, { FC } from "react";

import OverlayingPopup from "../../../UI/OverlayingPopup";
import QuestionnaireWidget from "../QuestionnaireWidget";
import styles from "./index.module.css";

type Props = {
  isOpen: boolean;
  onClose?: () => void;
};

const QuestionnairePopup: FC<Props> = ({ isOpen, onClose }) => {
  return (
    <OverlayingPopup isOpen={isOpen} onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.questionnaire}>
          <QuestionnaireWidget />
        </div>
      </div>
    </OverlayingPopup>
  );
};

export default QuestionnairePopup;
