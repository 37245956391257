import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import QuestionnairePopup from "../../features/questionnaire/QuestionnairePopup";
import { useRequiredQuestionnaire } from "../../generic/providers/RequiredQuestionnaireProvider";
import { useScreenType } from "../../lib/hooks/useScreenType";
import { PatientUrls } from "../../lib/routes";

const RequiredQuestionnaireLockLayout = () => {
  const screenType = useScreenType();
  const { requiredSectionPassed } = useRequiredQuestionnaire();

  if (requiredSectionPassed) {
    return <Outlet />;
  } else {
    return screenType === "mobile" ? (
      <Navigate to={PatientUrls.QUESTIONNAIRES} />
    ) : (
      <QuestionnairePopup isOpen={true} />
    );
  }
};

export default RequiredQuestionnaireLockLayout;
