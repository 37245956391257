import { isAxiosError } from "axios";
import { useEffect, useMemo, useState } from "react";
import React from "react";
import { Link } from "react-router-dom";

import { getCourierRequest, postCourierRequest } from "../../API/CourierService";
import { getSampleReception } from "../../API/SampleReception";
import { ReactComponent as CheckMarkIcon } from "../../images/checkmark_circle_purple.svg";
import { useFetching } from "../../lib/hooks/useFetch";
import { toISODate } from "../../lib/utils/toISODate";
import { useBindTest } from "../../queries/tests";
import { usePatientStore } from "../../store/usePatientStore";
import { useRightBottomPopup } from "../../store/useRightBottomPopup";
import { Button } from "../../UI/Button";
import { Input } from "../../UI/Input";
import analys from "./assets/analys.svg";
import hospital from "./assets/Hospitalbuilding.svg";
import Cars from "./assets/Logistics-rafiki.svg";
import map from "./assets/phone.svg";
import needCourierTestBg from "./assets/questionBackground.png";
import reportWithRecommendations from "./assets/reportWithRecommendations.svg";
import testBg from "./assets/testBg.webp";
import ComponentStep from "./componentStep";
import styles from "./index.module.css";

const errorSwitcher = (error: any): string => {
  if (error) {
    if (isAxiosError(error)) {
      switch (error.status) {
        case 404:
          return "Тест с таким ID не найден";
        default:
          return "Произошла ошибка";
      }
    }
    return "Произошла ошибка";
  }
  return "";
};

const RetakeTheTest = ({ time }: { time: string | undefined }) => {
  const [newDate, setNewDate] = useState("");

  const addThreeMonths = () => {
    if (time) {
      const date = new Date(time);
      date.setMonth(date.getMonth() + 3);
      const newDateStr = date.toISOString().slice(0, 10);
      setNewDate(newDateStr);
    }
  };

  function formatDate(inputDate: string) {
    if (newDate.length > 1) {
      const parts = inputDate.split("-");
      const day = parts[2];
      const month = parts[1];
      const year = parts[0];
      return `${day}.${month}.${year}`;
    } else return "";
  }

  useEffect(() => {
    addThreeMonths();
  }, []);
  return (
    <div style={{ background: `url(${needCourierTestBg})` }} className={styles.container}>
      <div className={styles.needCourierContainer}>
        <div className={styles.courierText}>
          <h3 style={{ lineHeight: "inherit" }}>Рекомендуемая дата повторной сдачи теста {formatDate(newDate)}</h3>
          <span style={{ width: "350px" }}>
            Приобретите новый тест для актуализации данных о состоянии вашего микробиома.
          </span>
          <Link
            className={styles.linkSubmit}
            style={{ textDecoration: "none", marginTop: 32 }}
            target="_blank"
            to={"https://novabiom.ru"}
          >
            <Button className={styles.submitButton} variant={"light"} size={"large"} type={"button"}>
              Приобрести тест
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

const NeedCourierContainer = () => {
  const [time, setTime] = useState<string>();
  const [date, setDate] = useState<Date>();
  const { needCourierTest, setTests, mainTest, tests } = usePatientStore((state) => state);
  const isTestPartner = useMemo<boolean>(() => {
    return !!mainTest?.partner || !!tests[0]?.partner;
  }, [mainTest?.partner, tests]);

  const { fetching: courierRequest } = useFetching();
  const { openPopup } = useRightBottomPopup((state) => state);
  useEffect(() => {
    if (time && date) {
      localStorage.setItem("ContainerRequest", JSON.stringify({ date: date, time: time }));
    }
  }, [date, time]);

  const onSubmit = () => {
    courierRequest(async () => {
      if (date && time && needCourierTest) {
        await postCourierRequest(toISODate(date).ISODate, time, needCourierTest.id);
        openPopup("Запись создана! Скоро с вами свяжутся для уточнения времени");
        localStorage.setItem("ContainerRequest", "");
        // TODO: Обновить тесты
        // setTests();
      }
    });
  };

  return (
    <div style={{ background: `url(${needCourierTestBg})` }} className={styles.container}>
      <div className={styles.needCourierContainer}>
        <div className={styles.courierText}>
          <h3>Спасибо!</h3>
          {isTestPartner ? (
            <span style={{ width: "350px" }}>
              Пожалуйста, передайте образец в клинику <b style={{ color: "white" }}>в течение 48</b> часов после сбора
              биоматериала.
            </span>
          ) : (
            <span>Менеджер свяжется с Вами для уточнения деталей доставки.</span>
          )}
        </div>
        <div className={styles.wrapImg}>
          <img src={isTestPartner ? hospital : map} className={styles.courierMap} />
        </div>
      </div>
    </div>
  );
};

interface TestStatusProps {
  testsStatus?: number;
  statusSwitcher: any;
  retakeTest?: string;
  partner?: boolean;
  setOpenModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TestStatus = (props: TestStatusProps) => {
  const { testsStatus, statusSwitcher, retakeTest, partner, setOpenModal } = props;
  const { mainTest, tests } = usePatientStore((state) => state);
  const { mutateAsync: bindTest, error } = useBindTest();
  const [testId, setTestId] = useState<string>("");
  const [appointment_time, setAppointment_time] = useState<string>();
  const [appointment_address, setAppointment_address] = useState<string>();
  const [status, setStatus] = useState(0);

  useEffect(() => {
    setStatus(statusSwitcher(mainTest?.status).step);
  }, [mainTest]);

  useEffect(() => {
    if (testsStatus) {
      setStatus(testsStatus);
    }
  }, [testsStatus]);

  const fetchDataAppointment_time = async () => {
    await getCourierRequest()
      .then((response) => {
        getSampleReception(response.data[0].id).then((response) => {
          setAppointment_time(response.data.appointment_time);
          setAppointment_address(response.data.address ? response.data.address : "");
        });
      })
      .catch(undefined);
  };

  useEffect(() => {
    fetchDataAppointment_time();
  }, []);

  const changeIsViewedSubmit = async () => {
    if (setOpenModal) {
      setOpenModal(true);
    }
  };

  const onSubmit = async () => {
    const formattedTest = testId.replace("—", "-").replace("–", "-").toUpperCase();
    await bindTest({ testSerialNumber: formattedTest });
  };

  function formatDate(inputDate: string | undefined) {
    if (inputDate) {
      const parts = inputDate.split("-");
      const day = parts[2];
      const month = parts[1];
      const year = parts[0].slice(2);
      return `${day}.${month}.${year}`;
    } else return "";
  }

  const stepRender = () => {
    switch (status) {
      case 1: {
        return <NeedCourierContainer />;
      }
      case 2: {
        return <NeedCourierContainer />;
      }
      case 3: {
        return <NeedCourierContainer />;
      }
      case 4: {
        return (
          <ComponentStep
            Img={!partner ? Cars : analys}
            TextTitle={
              !partner ? (
                <div>
                  {appointment_time ? "Курьер заберет образец" : "Вам назначен курьер"}{" "}
                  <span style={{ color: "#6100FF" }}>
                    {" "}
                    {formatDate(appointment_time?.slice(0, 10))}{" "}
                    {appointment_address?.length ? `по адресу ${appointment_address}` : ""}{" "}
                  </span>
                </div>
              ) : (
                <div>
                  Пока ваш биоматериал едет к нам, предлагаем закончить заполнение анкеты в{" "}
                  <span style={{ color: "#6100FF" }}>Личном кабинете</span>
                </div>
              )
            }
            Button={
              !partner ? (
                <></>
              ) : (
                <Link className={styles.linkSubmit} to={"/questionnaires"}>
                  <Button type="submit" variant="white" size="medium" className={styles.buttonBlue}>
                    Заполнить анкету
                  </Button>
                </Link>
              )
            }
          />
        );
      }
      case 5: {
        return (
          <ComponentStep
            Img={analys}
            TextTitle={
              <div>
                Пока ваш биоматериал едет к нам, предлагаем закончить заполнение анкеты в{" "}
                <span style={{ color: "#6100FF" }}>Личном кабинете</span>
              </div>
            }
            TextSmall={"Это поможет нам подстроить рекомендации под ваш привычный образ жизни"}
            Button={
              <Link className={styles.linkSubmit} to={"/questionnaires"}>
                <Button type="submit" variant="white" size="medium" className={styles.buttonBlue}>
                  Заполнить анкету
                </Button>
              </Link>
            }
          />
        );
      }
      case 6: {
        return (
          <ComponentStep
            Img={analys}
            TextTitle={
              <div>
                Пока ваш биоматериал едет к нам, предлагаем закончить заполнение анкеты в{" "}
                <span style={{ color: "#6100FF" }}>Личном кабинете</span>
              </div>
            }
            TextSmall={"Это поможет нам подстроить рекомендации под ваш привычный образ жизни"}
            Button={
              <Link className={styles.linkSubmit} to={"/questionnaires"}>
                <Button type="submit" variant="white" size="medium" className={styles.buttonBlue}>
                  Заполнить анкету
                </Button>
              </Link>
            }
          />
        );
      }
      case 7: {
        return (
          <ComponentStep
            Img={analys}
            TextTitle={
              <div>
                Пока ваш биоматериал едет к нам, предлагаем закончить заполнение анкеты в{" "}
                <span style={{ color: "#6100FF" }}>Личном кабинете</span>
              </div>
            }
            TextSmall={"Это поможет нам подстроить рекомендации под ваш привычный образ жизни"}
            Button={
              <Link className={styles.linkSubmit} to={"/questionnaires"}>
                <Button type="submit" variant="white" size="medium" className={styles.buttonBlue}>
                  Заполнить анкету
                </Button>
              </Link>
            }
          />
        );
      }
      case 8: {
        return (
          <ComponentStep
            Img={analys}
            TextTitle={
              <div>
                Пока ваш биоматериал едет к нам, предлагаем закончить заполнение анкеты в{" "}
                <span style={{ color: "#6100FF" }}>Личном кабинете</span>
              </div>
            }
            TextSmall={"Это поможет нам подстроить рекомендации под ваш привычный образ жизни"}
            Button={
              <Link className={styles.linkSubmit} to={"/questionnaires"}>
                <Button type="submit" variant="white" size="medium" className={styles.buttonBlue}>
                  Заполнить анкету
                </Button>
              </Link>
            }
          />
        );
      }
      case 9: {
        return (
          <ComponentStep
            Img={analys}
            TextTitle={
              <div>
                Пока ваш биоматериал едет к нам, предлагаем закончить заполнение анкеты в{" "}
                <span style={{ color: "#6100FF" }}>Личном кабинете</span>
              </div>
            }
            TextSmall={"Это поможет нам подстроить рекомендации под ваш привычный образ жизни"}
            Button={
              <Link className={styles.linkSubmit} to={"/questionnaires"}>
                <Button type="submit" variant="white" size="medium" className={styles.buttonBlue}>
                  Заполнить анкету
                </Button>
              </Link>
            }
          />
        );
      }
      case 10: {
        return (
          <ComponentStep
            Img={analys}
            TextTitle={
              <div>
                Пока ваш биоматериал едет к нам, предлагаем закончить заполнение анкеты в{" "}
                <span style={{ color: "#6100FF" }}>Личном кабинете</span>
              </div>
            }
            TextSmall={"Это поможет нам подстроить рекомендации под ваш привычный образ жизни"}
            Button={
              <Link className={styles.linkSubmit} to={"/questionnaires"}>
                <Button type="submit" variant="white" size="medium" className={styles.buttonBlue}>
                  Заполнить анкету
                </Button>
              </Link>
            }
          />
        );
      }
      case 11: {
        return (
          <ComponentStep
            Img={reportWithRecommendations}
            Button={
              <Button
                type="submit"
                variant="white"
                onClick={changeIsViewedSubmit}
                size="medium"
                className={styles.buttonBlue}
              >
                Перейти к отчету
              </Button>
            }
          />
        );
      }
      case 12: {
        return <RetakeTheTest time={retakeTest} />;
      }
      default: {
        return <NeedCourierContainer />;
      }
    }
  };

  const [imageLoaded, setImageLoaded] = useState<boolean>(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
    };
    img.src = testBg;
  }, [testBg]);

  return (
    <>
      {tests.length === 0 || status === 0 ? (
        <div
          style={{
            padding: "20px 24px 32px 24px",
            backgroundImage: imageLoaded
              ? `url(${testBg})`
              : "linear-gradient(173.38deg, #6100ff 1.26%, #7825ff 103.24%)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          className={`${styles.container} ${styles.containerWrap}`}
        >
          <div
            style={{ padding: "20px 24px 32px 24px" }}
            className={`${styles.container} ${styles.containerWithoutTests}`}
          >
            <div className={styles.title}>С вами скоро свяжется специалист для уточнения деталей доставки теста</div>
            <div className={styles.linTest}>Уже получили тест?</div>
            <div className={styles.info}>
              <p>Введите ID полученного теста микробиоты</p>
              <span>Он указан на пробирке, которая входит в состав набора</span>
            </div>
            <div className={styles.inputWrapper}>
              <Input
                error={errorSwitcher(error)}
                onEnter={onSubmit}
                onChange={(e) => {
                  setTestId(e.target.value);
                }}
                placeholder="Введите ID вашего теста"
                type="text"
                className={styles.input}
              />
              <Button
                type="submit"
                variant="white"
                size="small"
                onClick={onSubmit}
                className={styles.checkmark}
                leftIcon={<CheckMarkIcon />}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.step}>{stepRender()}</div>
      )}
    </>
  );
};
