import React from "react";

import OrgPatientContentWidget from "../../components/OrgPatientContentWidget";

const OrgPatientPage = () => {
  return (
    <>
      <OrgPatientContentWidget />
    </>
  );
};

export default OrgPatientPage;
