import React, { ReactNode, useMemo } from "react";

import { KnowledgeIcon } from "../../components/Aside/assets/knowledge";
import { MedCardIcon } from "../../components/Aside/assets/notes_medical";
import { PatientsIcon } from "../../components/Aside/assets/patients";
import { ProfileIcon } from "../../components/Aside/assets/profile";
import { RecommendIcon } from "../../components/Aside/assets/recommendations";
import { SupportIcon } from "../../components/Aside/assets/support";
import MenuGenerationAsideCaption from "../../components/MenuGenerationAsideCaption";
import QuestionnaireWarning from "../../components/QuestionnaireWarning";
import BlankIcon from "../../images/blankIcon";
import { DocAsideIcon } from "../../images/DocAsideIcon";
import { MenuIcon } from "../../images/MenuIcon";
import QuestionnairesIcon from "../../images/QuestionnairesIcon";
import ReportIcon from "../../images/ReportIcon";
import SpecialistsIcon from "../../images/SpecialistsIcon";
import { TestPickerIcon } from "../../images/test_picker";
import { useDoctorStore } from "../../store/useDoctorStore";
import { usePatientStore } from "../../store/usePatientStore";
import { useTestStore } from "../../store/useTestStore";
import { DoctorUrls, OrgPatientUrls, OrgUrls, PatientUrls } from "../routes";
import { useUser } from "./useUser";

export type AsideNodePosition = "bottom";

export type AsideNodeIsActiveStrategy = "pathnameFullMatch" | "pathnameStartWith" | undefined;

export interface AsideNode {
  caption: string | ReactNode;
  link?: string;
  icon?: ReactNode;
  children?: AsideNode[];
  rightBlock?: ReactNode;
  onClick?: () => void;
  position?: AsideNodePosition;
  isActiveStrategy?: AsideNodeIsActiveStrategy;
}

type AsideDataVariant = "doctor" | "patient" | "org" | "patientReview" | "empty";

export const useAsideData = (setTestPickerPopupIsOpen: (value: boolean) => void) => {
  const role = useUser().user.type;
  const { mainTest, tests } = usePatientStore((state) => state);
  const { idDiet } = useTestStore((state) => state);
  const { reviewPatient } = useDoctorStore((state) => state);
  const asideVariant: AsideDataVariant = useMemo(() => {
    if (role === "patient") {
      return "patient";
    } else if (role === "doctor") {
      if (reviewPatient) {
        return "patientReview";
      }
      return "doctor";
    } else if (role === "org") {
      return "org";
    }
    return "empty";
  }, [role, reviewPatient]);

  const nodes = useMemo(() => {
    let _nodes = asideDataVariants[asideVariant];
    if (asideVariant === "patient") {
      if (tests.length) {
        _nodes = [getTestsNode(() => setTestPickerPopupIsOpen(true)), ..._nodes];
      }
      if (!idDiet) {
        _nodes = _nodes.filter((e) => e.link !== PatientUrls.MENU_GENERATOR);
      }
      if (mainTest?.partner?.startsWith("mother_and_child")) {
        _nodes = _nodes.filter((e) => e.caption !== "Специалисты");
      }
    }
    return _nodes;
  }, [asideVariant, mainTest, tests, idDiet, setTestPickerPopupIsOpen]);

  return { nodes };
};

const getTestsNode = (onClick: () => void): AsideNode => {
  return {
    caption: `${
      usePatientStore.getState().mainTest
        ? `Тест за ${usePatientStore.getState().mainTest?.date?.split("-").reverse().join(".")}`
        : "Новый тест"
    }`,
    icon: <TestPickerIcon />,
    rightBlock: (
      <svg width="5" height="10" viewBox="0 0 5 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 5L0 10L4.37114e-07 0L5 5Z" fill="white" />
      </svg>
    ),
    onClick: onClick,
  };
};

const orgAsideData: AsideNode[] = [
  {
    caption: "Отчет",
    icon: <ReportIcon />,
    link: String(OrgPatientUrls.REPORT),
  },
  {
    caption: "Анкета",
    icon: <MedCardIcon />,
    link: String(OrgPatientUrls.QUESTIONNAIRES),
  },
  {
    caption: "Профиль",
    icon: <ProfileIcon />,
    link: OrgUrls.PROFILE,
    position: "bottom",
  },
];

const patientReviewAsideData: AsideNode[] = [
  {
    caption: "Отчет",
    icon: <ReportIcon />,
    link: PatientUrls.REPORT,
  },
  {
    caption: "Анкета и тесты",
    icon: <MedCardIcon />,
    isActiveStrategy: "pathnameStartWith",
    link: PatientUrls.QUESTIONNAIRES,
  },
  {
    caption: "DOC",
    icon: <DocAsideIcon />,
    link: DoctorUrls.INTERPRETATION,
  },
];

const doctorAsideData: AsideNode[] = [
  {
    caption: "Консультации",
    icon: <RecommendIcon />,
    link: DoctorUrls.CONSULTATIONS,
  },
  {
    caption: "Пациенты",
    icon: <PatientsIcon />,
    link: DoctorUrls.PATIENTS,
  },
  {
    caption: "Профиль",
    icon: <ProfileIcon />,
    link: DoctorUrls.PROFILE,
  },
];

const patientAsideData: AsideNode[] = [
  {
    caption: "Отчет",
    icon: <ReportIcon />,
    link: PatientUrls.REPORT,
  },
  {
    caption: "Специалисты",
    icon: <SpecialistsIcon />,
    link: PatientUrls.SPECIALISTS,
  },
  {
    caption: <MenuGenerationAsideCaption />,
    icon: <MenuIcon />,
    link: PatientUrls.MENU_GENERATOR,
  },
  {
    caption: "Заключения",
    icon: <BlankIcon white={true} />,
    link: PatientUrls.CONCLUSIONS,
    isActiveStrategy: "pathnameStartWith",
  },
  {
    caption: "Анкета",
    icon: <QuestionnairesIcon />,
    rightBlock: <QuestionnaireWarning />,
    link: PatientUrls.QUESTIONNAIRES,
  },
  {
    caption: "Профиль",
    icon: <ProfileIcon />,
    position: "bottom",
    link: PatientUrls.PROFILE,
  },
  {
    caption: "База знаний",
    icon: <KnowledgeIcon />,
    link: PatientUrls.KNOWLEDGE,
    isActiveStrategy: "pathnameStartWith",
    position: "bottom",
  },
  {
    caption: "Поддержка",
    icon: <SupportIcon />,
    link: PatientUrls.SUPPORT,
    position: "bottom",
  },
];

const asideDataVariants: Record<AsideDataVariant, AsideNode[]> = {
  doctor: doctorAsideData,
  org: orgAsideData,
  patientReview: patientReviewAsideData,
  patient: patientAsideData,
  empty: [],
};
