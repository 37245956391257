import { AnimatePresence, motion } from "framer-motion";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";

import { useRequiredQuestionnaire } from "../../../generic/providers/RequiredQuestionnaireProvider";
import { useCurrentItemState } from "../../../lib/hooks/useCurrentItemState";
import useWindowSize from "../../../lib/hooks/useWindowSize";
import { Section } from "../../../lib/models/questionnaire";
import { useFetchAvailableSections } from "../../../queries/questionnaire";
import InteractiveSectionPassing from "../QuestionnairePassing/InteractiveSectionPassing";
import RequiredQuestionsPassing from "../QuestionnairePassing/RequiredSectionPassing";
import QuestionnairesList from "../QuestionnairesList";
import styles from "./index.module.css";

const questionnaireContainer = {
  hidden: { opacity: 0, x: 100 },
  exit: {
    opacity: 0,
    scale: 1,
    x: -100,
    transition: {
      delayChildren: 0.0,
      staggerChildren: 0.1,
    },
  },
  menuIsOpen: {
    x: 425,
    opacity: 0.5,
  },
  visible: {
    opacity: 1,
    x: 0,
  },
};

const listVariants = {
  hidden: { opacity: 0, x: -400 },
  exit: {
    opacity: 0,
    scale: 1,
    x: -400,
  },
  visible: {
    opacity: 1,
    x: 0,
  },
};

type QuestionnaireWidgetContextType = {
  menuIsOpen: boolean;
  setMenuIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentSection: Section | null;
  currentSectionIndex: number;
  setCurrentSectionIndex: (index: number) => void;
  goToNextSection: () => void;
  totalProgress: number;
  setCurrentSection: (section: Section) => void;
  nextSectionIsAvailable: boolean;
};

const QuestionnaireWidgetContext = React.createContext<QuestionnaireWidgetContextType | null>(null);

export const useQuestionnaireWidgetContext = () => {
  const context = useContext(QuestionnaireWidgetContext);

  if (!context) {
    throw new Error("useQuestionnaireWidget must be used within a QuestionnaireWidgetProvider");
  }

  return context;
};

const QuestionnaireWidget = () => {
  const { width } = useWindowSize();

  const ref = useRef<HTMLDivElement>(null);
  const [questionnaireWidth, setQuestionnaireWidth] = useState<string>("100%");

  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  useEffect(() => {
    const container = ref.current;
    if (container && container.clientWidth) {
      setQuestionnaireWidth(container.clientWidth + "px");
    }
  }, [width]);

  const { data: sections } = useFetchAvailableSections();

  const {
    currentItem: currentSection,
    nextItem: nextSection,
    currentIndex: currentSectionIndex,
    setCurrentIndex: setCurrentSectionIndex,
    goToNext: goToNextSection,
    setCurrentItem: setCurrentSection,
  } = useCurrentItemState<Section>({
    array: sections,
    findIndexFn: (item) => sections.findIndex((e) => e.id === item.id),
  });

  const totalProgress = useMemo(() => {
    return Math.round(sections.reduce((total, section) => total + Math.round(section.progress), 0) / sections.length);
  }, [sections]);

  return (
    <QuestionnaireWidgetContext.Provider
      value={{
        menuIsOpen,
        setMenuIsOpen,
        currentSection,
        goToNextSection,
        totalProgress,
        setCurrentSectionIndex,
        currentSectionIndex,
        setCurrentSection,
        nextSectionIsAvailable: !!nextSection,
      }}
    >
      <div ref={ref} className={styles.container}>
        <AnimatePresence mode="popLayout">
          {menuIsOpen && (
            <motion.div
              className={styles.listWrapper}
              variants={listVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              key="QuestionnairesListContainer"
            >
              <div className={styles.list}>
                <QuestionnairesList />
              </div>
            </motion.div>
          )}
          <motion.div
            onClick={() => {
              if (menuIsOpen) {
                setMenuIsOpen(false);
              }
            }}
            className={styles.questionnaire}
            style={
              menuIsOpen
                ? {
                    width: questionnaireWidth,
                    position: "absolute",
                    display: `${width < 988 && "none"}`,
                  }
                : {}
            }
            variants={questionnaireContainer}
            initial="hidden"
            animate={menuIsOpen ? "menuIsOpen" : "visible"}
            exit="exit"
            key="QuestionnairePassingAnimated"
          >
            {currentSection && (
              <InteractiveSectionPassing
                key={currentSection.id}
                section={currentSection}
                nextSection={sections.at(1 + sections.findIndex((s) => s.id === currentSection.id))}
              />
            )}
          </motion.div>
        </AnimatePresence>
      </div>
    </QuestionnaireWidgetContext.Provider>
  );
};

const QuestionnaireWidgetWrapper = () => {
  const { requiredQuestions, requiredQuestionsProgress, requiredSectionPassed, requiredSectionPassedHandler } =
    useRequiredQuestionnaire();

  if (!requiredSectionPassed) {
    return (
      <div className={styles.container}>
        <div className={styles.questionnaire}>
          <RequiredQuestionsPassing
            requiredQuestions={requiredQuestions}
            progress={requiredQuestionsProgress}
            onSectionReadyButton={requiredSectionPassedHandler}
          />
        </div>
      </div>
    );
  }

  return <QuestionnaireWidget />;
};

export default QuestionnaireWidgetWrapper;
