import React, { FC, useEffect, useState } from "react";

import { ReactComponent as CheckIcon } from "../../../images/docCheck.svg";
import cn from "../../../lib/utils/cn";
import { Button } from "../../../UI/Button";
import ToggleDropdown from "../../../UI/ToggleDropdown/ToggleDropdown";
import styles from "./DocInterpretationDropdown.module.css";

type Props = {
  name: string;
  initValue: string;
  onSubmit?: (value: string) => void;
};

const DocInterpretationDropdown: FC<Props> = ({ initValue, name, onSubmit }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [value, setValue] = useState<string>(initValue);

  useEffect(() => {
    setValue(initValue);
  }, [initValue]);

  return (
    <div className={styles.container}>
      <div className={styles.header} role="button" onClick={() => setIsOpen(!isOpen)}>
        <CheckIcon style={{ color: initValue.length ? "#7BC975" : "#C8CBCD" }} />
        <p>{name}</p>
        <ToggleDropdown isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
      <div className={cn(styles.body, isOpen && styles.bodyIsOpen)}>
        <div>
          <textarea
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="Напишите свой комментарий"
            className={styles.textarea}
          />
          <Button
            onClick={() => {
              onSubmit && onSubmit(value);
            }}
            disabled={initValue === value}
            className={styles.button}
            variant="primary"
            size="small"
          >
            Сохранить
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DocInterpretationDropdown;
