import React, { lazy } from "react";
import { Navigate, Route } from "react-router-dom";

import PatientLockLayout from "../components/PatientLockLayout";
import ScrollTopLayout from "../components/ScrollTopLayout";
import PatientProvider from "../generic/providers/PatientProvider";
import RequiredQuestionnaireProvider from "../generic/providers/RequiredQuestionnaireProvider";
import RequiredQuestionnaireLockLayout from "../layouts/RequiredQuestionnaireLockLayout";
import UserLayout from "../layouts/UserLayout";
import { PatientUrls } from "../lib/routes";
import { NotFound } from "../pages/NotFound";
import NewQuestionnairesPage from "../pages/QuestionnairePage";
import ReportProgressPage from "../pages/ReportProgressPage";
const CartPage = lazy(() => import("../pages/CartPage"));
const KnowledgePage = lazy(() => import("../pages/KnowledgePage"));
const MenuPage = lazy(() => import("../pages/MenuPage"));
const PaymentErrorEmptyPage = lazy(() => import("../pages/PaymentErrorEmptyPage"));
const PaymentSuccessEmptyPage = lazy(() => import("../pages/PaymentSuccessEmptyPage"));
const ProfilePage = lazy(() => import("../pages/ProfilePage"));
const ReportPage = lazy(() => import("../pages/ReportPage"));
const SpecialistsPage = lazy(() => import("../pages/SpecialistsPage"));
const SupportPage = lazy(() => import("../pages/SupportPage"));
const PatientConclusionsPage = lazy(() => import("../pages/PatientConclusionsPage"));

const PatientRoutes = () => {
  return (
    <Route element={<PatientProvider />}>
      <Route element={<UserLayout />}>
        <Route path="/" element={<Navigate to={PatientUrls.REPORT} />} />
        <Route element={<RequiredQuestionnaireProvider />}>
          <Route element={<ScrollTopLayout />}>
            <Route path={PatientUrls.PROFILE} element={<ProfilePage />} />
            <Route path={PatientUrls.QUESTIONNAIRES} element={<NewQuestionnairesPage />} />
            <Route element={<RequiredQuestionnaireLockLayout />}>
              <Route path={PatientUrls.SUPPORT} element={<SupportPage />} />
              <Route path={PatientUrls.CART} element={<CartPage />} />
              <Route path="/payment/success/:params?" element={<PaymentSuccessEmptyPage />} />
              <Route path="/payment/error/:params?" element={<PaymentErrorEmptyPage />} />
              <Route path={PatientUrls.KNOWLEDGE} element={<KnowledgePage />}></Route>
              <Route path={PatientUrls.KNOWLEDGE_ARTICLE} element={<KnowledgePage />} />
              <Route path={PatientUrls.SPECIALISTS} element={<SpecialistsPage />} />
              <Route path={PatientUrls.PROGRESS} element={<ReportProgressPage />} />
              <Route element={<PatientLockLayout />}>
                <Route path={PatientUrls.REPORT} element={<ReportPage />} />
                <Route path={PatientUrls.MENU_GENERATOR} element={<MenuPage />} />
                <Route path={PatientUrls.RESULTS_CONCLUSIONS} element={<PatientConclusionsPage />} />
                <Route path={PatientUrls.CONSULTATIONS_CONCLUSIONS} element={<PatientConclusionsPage />} />
                <Route path={PatientUrls.CONCLUSIONS} element={<PatientConclusionsPage />} />
              </Route>
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Route>
    </Route>
  );
};

export default PatientRoutes;
