import React, { useEffect, useState } from "react";

import Analysis from "./assets/analysis";
import checkCircle from "./assets/check_circle.svg";
import CourierCalled from "./assets/courierCalled";
import OnRoad from "./assets/onRoad";
import ReportReady from "./assets/reportReady";
import Resurrender from "./assets/resurrender";
import TestPaid from "./assets/testPaid";
import styles from "./index.module.css";

type statusStep = {
  step: number;
};

interface ReportProgressProps {
  partner: boolean;
  step: number;
  selectReport: number;
  retakeTest?: string;
  setSelectReport: React.Dispatch<React.SetStateAction<number>>;
}
const ReportProgress = (props: ReportProgressProps) => {
  const { step, setSelectReport, selectReport, retakeTest, partner } = props;

  const [newDate, setNewDate] = useState<string>("");
  const selectReportItem = (select: number) => {
    if (step > select || step === select || (select === 12 && step > 10)) setSelectReport(select);
  };

  const addThreeMonths = () => {
    if (retakeTest) {
      const date = new Date(retakeTest);
      date.setMonth(date.getMonth() + 3);
      const newDateStr = date.toISOString().slice(0, 10);
      setNewDate(newDateStr);
    }
  };
  function formatDate(inputDate: string) {
    if (newDate.length > 1) {
      const parts = inputDate.split("-");
      const day = parts[2];
      const month = parts[1];
      const year = parts[0];
      return `${day}.${month}.${year}`;
    }
  }
  useEffect(() => {
    addThreeMonths();
  }, []);

  return (
    <div className={`${styles.wrapContainer} ${step > 0 && styles.fogTop}`}>
      <div className={styles.container} style={{ transition: "all 0.5s" }}>
        <div onClick={() => selectReportItem(2)} className={`${styles.wrapBlock} ${styles.activate}`}>
          <div className={styles.content}>
            <TestPaid className={styles.img} isCheck={[1, 2, 3].includes(selectReport)} />
            <div className={`${styles.textBlock}`}>
              <span className={styles.step}>Шаг 1</span>
              <span className={styles.testTitle}>Тест оплачен</span>
              <span className={styles.bodyText}>После получения теста введите его ID.</span>
            </div>
          </div>
          {step ? <img src={checkCircle} /> : ""}
        </div>

        <div onClick={() => selectReportItem(4)} className={`${styles.wrapBlock} ${step > 3 && styles.activate}`}>
          <div className={styles.content}>
            <CourierCalled
              className={styles.img}
              isCheck={selectReport === 4}
              color={step > 3 ? "#6100FF" : "#C4BCDB"}
            />
            <div className={`${styles.textBlock}`}>
              <span className={styles.step}>Шаг 2</span>
              <span className={styles.testTitle}>{partner ? "Биоматериал передан в клинику" : "Курьер вызван"}</span>
              <span className={styles.bodyText}>
                {partner
                  ? "Совсем скоро сотрудники клиники передадут биоматериал нашему курьеру"
                  : "Вам предстоит собрать биоматериал и вызвать курьера для его забора."}
              </span>
            </div>
          </div>
          {/*<img src={courierCalled}/>*/}
          {step > 3 && <img src={checkCircle} />}
        </div>

        <div onClick={() => selectReportItem(5)} className={`${styles.wrapBlock} ${step > 4 && styles.activate}`}>
          <div className={styles.content}>
            <OnRoad className={styles.img} isCheck={selectReport === 5} color={step > 4 ? "#6100FF" : "#C4BCDB"} />
            <div className={`${styles.textBlock}`}>
              <span className={styles.step}>Шаг 3</span>
              <span className={styles.testTitle}>На пути в лабораторию</span>
              <span className={styles.bodyText}>
                Ваш биоматериал уже направляется в лабораторию. Полученные данные находятся под надежной защитой на всех
                этапах исследования.
              </span>
            </div>
          </div>
          {/*<img src={courierCalled}/>*/}
          {step > 4 && <img src={checkCircle} />}
        </div>

        <div onClick={() => selectReportItem(6)} className={`${styles.wrapBlock} ${step > 5 && styles.activate}`}>
          <div className={styles.content}>
            <Analysis
              className={styles.img}
              isCheck={[6, 7, 8, 9, 10].includes(selectReport)}
              color={step > 5 ? "#6100FF" : "#C4BCDB"}
            />
            <div className={`${styles.textBlock}`}>
              <span className={styles.step}>Шаг 4</span>
              <span className={styles.testTitle}>Анализ</span>
              <span className={styles.bodyText}>
                Ваш биоматериал прибыл в лабораторию для проведения анализа. Совсем скоро отчет станет доступен в личном
                кабинете.
              </span>
            </div>
          </div>
          {/*<img src={courierCalled}/>*/}
          {step > 5 && <img src={checkCircle} />}
        </div>

        <div onClick={() => selectReportItem(11)} className={`${styles.wrapBlock} ${step > 10 && styles.activate}`}>
          <div className={styles.content}>
            <ReportReady
              className={styles.img}
              isCheck={selectReport === 11}
              color={step > 10 ? "#6100FF" : "#C4BCDB"}
            />
            <div className={`${styles.textBlock}`}>
              <span className={styles.step}>Шаг 5</span>
              <span className={styles.testTitle}>Отчет с персональными рекомендациями готов</span>
              <span className={styles.bodyText}>Все отлично! Все этапы успешно пройдены.</span>
            </div>
          </div>
          {/*<img src={courierCalled}/>*/}
          {step > 10 && <img src={checkCircle} />}
        </div>

        <div
          onClick={() => selectReportItem(12)}
          className={`${styles.wrapBlock} ${step > 10 && `${styles.activate} ${styles.lastWrapBlock}`}`}
        >
          <div className={styles.content}>
            <Resurrender
              className={styles.img}
              isCheck={selectReport === 12}
              color={step > 10 ? "#6100FF" : "#C4BCDB"}
            />
            <div className={`${styles.textBlock}`}>
              <span className={styles.testTitle}>Рекомендуемая дата повторной сдачи теста {formatDate(newDate)}</span>
              <span className={styles.bodyText}>
                Рекомендуем вам повторно сдать тест, чтобы отследить динамику улучшений и скорректировать траекторию
                здоровья.
              </span>
            </div>
          </div>
          {step > 10 && <img src={checkCircle} />}
        </div>
      </div>

      {/*<DenialResponsibility openModal={openModal} closeModal={()=>setOpenModal(false)}/>*/}
    </div>
  );
};

export default ReportProgress;
