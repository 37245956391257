import React from "react";
interface propCourierCalled {
  className: string;
  color?: string;
  isCheck?: boolean;
}

const CourierCalled = (props: propCourierCalled) => {
  const { className, color = "#6100FF", isCheck = false } = props;
  return (
    <svg
      className={className}
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1.5" y="1.5" width="61" height="61" rx="30.5" fill={isCheck ? "#6100FF" : "white"} />
      <mask id="mask0_11303_31645" maskUnits="userSpaceOnUse" x="16" y="16" width="32" height="32">
        <rect x="16" y="16" width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_11303_31645)">
        <path
          d="M35.3974 38.0641L30.6641 33.3307V26.6641H33.3307V32.2307L37.2641 36.1641L35.3974 38.0641ZM30.6641 23.9974V21.3307H33.3307V23.9974H30.6641ZM39.9974 33.3307V30.6641H42.6641V33.3307H39.9974ZM30.6641 42.6641V39.9974H33.3307V42.6641H30.6641ZM21.3307 33.3307V30.6641H23.9974V33.3307H21.3307ZM31.9974 45.3307C30.153 45.3307 28.4196 44.9807 26.7974 44.2807C25.1752 43.5807 23.7641 42.6307 22.5641 41.4307C21.3641 40.2307 20.4141 38.8196 19.7141 37.1974C19.0141 35.5752 18.6641 33.8418 18.6641 31.9974C18.6641 30.153 19.0141 28.4196 19.7141 26.7974C20.4141 25.1752 21.3641 23.7641 22.5641 22.5641C23.7641 21.3641 25.1752 20.4141 26.7974 19.7141C28.4196 19.0141 30.153 18.6641 31.9974 18.6641C33.8418 18.6641 35.5752 19.0141 37.1974 19.7141C38.8196 20.4141 40.2307 21.3641 41.4307 22.5641C42.6307 23.7641 43.5807 25.1752 44.2807 26.7974C44.9807 28.4196 45.3307 30.153 45.3307 31.9974C45.3307 33.8418 44.9807 35.5752 44.2807 37.1974C43.5807 38.8196 42.6307 40.2307 41.4307 41.4307C40.2307 42.6307 38.8196 43.5807 37.1974 44.2807C35.5752 44.9807 33.8418 45.3307 31.9974 45.3307ZM31.9974 42.6641C34.9752 42.6641 37.4974 41.6307 39.5641 39.5641C41.6307 37.4974 42.6641 34.9752 42.6641 31.9974C42.6641 29.0196 41.6307 26.4974 39.5641 24.4307C37.4974 22.3641 34.9752 21.3307 31.9974 21.3307C29.0196 21.3307 26.4974 22.3641 24.4307 24.4307C22.3641 26.4974 21.3307 29.0196 21.3307 31.9974C21.3307 34.9752 22.3641 37.4974 24.4307 39.5641C26.4974 41.6307 29.0196 42.6641 31.9974 42.6641Z"
          fill={isCheck ? "white" : color}
        />
      </g>
      <rect x="1.5" y="1.5" width="61" height="61" rx="30.5" stroke={color} strokeWidth="3" />
    </svg>
  );
};

export default CourierCalled;
