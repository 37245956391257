import { FC, useEffect } from "react";
import React from "react";

import { LoginLayout } from "../../components/Login/LoginLayout";
import { SignInForm } from "../../components/Login/SignInForm";
import { hideChat } from "../../lib/utils/hideChat";

const SignInPage: FC = () => {
  useEffect(() => {
    hideChat();
  }, []);
  return (
    <>
      <LoginLayout>
        <SignInForm />
      </LoginLayout>
    </>
  );
};

export default SignInPage;
