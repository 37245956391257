type toISODateType = {
  ISODate: string;
  ISOTime: string;
};

export const toISODate = (date: Date): toISODateType => {
  const ISO = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
  const ISODate: string = ISO.split("T")[0];
  const ISOTime: string = ISO.split("T")[1].slice(0, 5);
  return { ISODate, ISOTime };
};
