import { FC, useEffect } from "react";
import React from "react";
import { useParams } from "react-router-dom";

import { LoginLayout } from "../../components/Login/LoginLayout";
import { ResetPassword } from "../../components/Login/ResetPassword";
import { hideChat } from "../../lib/utils/hideChat";

export const ResetPasswordPage: FC = () => {
  const { UID, token } = useParams();
  useEffect(() => {
    hideChat();
  }, []);
  return (
    <LoginLayout>
      <ResetPassword UID={UID} token={token} />
    </LoginLayout>
  );
};
