import React, { FC, ReactNode } from "react";

import { ReactComponent as LoveCheckList } from "../../images/loveCheckList.svg";
import { Button } from "../../UI/Button";
import styles from "./index.module.css";

type buttonOptionsType = {
  caption: string;
  onClick: () => void;
};

type Props = {
  children: ReactNode;
  buttonOptions?: buttonOptionsType;
  showShadow?: boolean;
};

const EmptyDataBlock: FC<Props> = ({ children, buttonOptions, showShadow = false }) => {
  return (
    <div className={styles.container}>
      <div>
        {showShadow && <div className={styles.shadow} />}
        <LoveCheckList />
        {children}
        {buttonOptions && (
          <Button
            onClick={buttonOptions.onClick}
            className={styles.button}
            variant="primary"
            size="large"
            type="button"
          >
            {buttonOptions.caption}
          </Button>
        )}
      </div>
    </div>
  );
};

export default EmptyDataBlock;
