import React from "react";

interface propsResurrender {
  className: string;
  color?: string;
  isCheck?: boolean;
}
const Resurrender = (props: propsResurrender) => {
  const { className, color = "#6100FF", isCheck = true } = props;
  return (
    <svg
      width="64"
      className={className}
      height="65"
      viewBox="0 0 64 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1.5" y="2" width="61" height="61" rx="30.5" fill={isCheck ? "#6100FF" : "white"} />
      <mask id="mask0_11303_31701" maskUnits="userSpaceOnUse" x="16" y="16" width="32" height="33">
        <rect x="16" y="16.5" width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_11303_31701)">
        <path
          d="M21.3359 43.1693V40.5026H25.0026L24.4693 40.0359C23.3137 39.0137 22.5026 37.847 22.0359 36.5359C21.5693 35.2248 21.3359 33.9026 21.3359 32.5693C21.3359 30.1026 22.0748 27.9082 23.5526 25.9859C25.0304 24.0637 26.9582 22.7915 29.3359 22.1693V24.9693C27.7359 25.547 26.447 26.5304 25.4693 27.9193C24.4915 29.3082 24.0026 30.8582 24.0026 32.5693C24.0026 33.5693 24.1915 34.5415 24.5693 35.4859C24.947 36.4304 25.5359 37.3026 26.3359 38.1026L26.6693 38.4359V35.1693H29.3359V43.1693H21.3359ZM34.6693 42.8359V40.0359C36.2693 39.4582 37.5582 38.4748 38.5359 37.0859C39.5137 35.697 40.0026 34.147 40.0026 32.4359C40.0026 31.4359 39.8137 30.4637 39.4359 29.5193C39.0582 28.5748 38.4693 27.7026 37.6693 26.9026L37.3359 26.5693V29.8359H34.6693V21.8359H42.6693V24.5026H39.0026L39.5359 24.9693C40.6248 26.0582 41.4193 27.2415 41.9193 28.5193C42.4193 29.797 42.6693 31.1026 42.6693 32.4359C42.6693 34.9026 41.9304 37.097 40.4526 39.0193C38.9748 40.9415 37.047 42.2137 34.6693 42.8359Z"
          fill={isCheck ? "white" : color}
        />
      </g>
      <rect x="1.5" y="2" width="61" height="61" rx="30.5" stroke={color} strokeWidth="3" />
    </svg>
  );
};

export default Resurrender;
