import React from "react";
import { Outlet } from "react-router-dom";
import { shallow } from "zustand/shallow";

import ReportProgressPage from "../../pages/ReportProgressPage";
import { usePatientStore } from "../../store/usePatientStore";

const PatientLockLayout = () => {
  const { isTestReady, mainTestIsViewed } = usePatientStore((state) => state, shallow);

  return <>{isTestReady && mainTestIsViewed ? <Outlet /> : <ReportProgressPage />}</>;
};

export default PatientLockLayout;
