import { ReactNode, useEffect, useState } from "react";
import { createPortal } from "react-dom";

const PrintPortal = ({ children }: { children: ReactNode }) => {
  const [container] = useState(() => {
    const elem = document.createElement("div");
    elem.className = "printPortal";
    return elem;
  });

  useEffect(() => {
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, []);

  return createPortal(children, container);
};

export default PrintPortal;
