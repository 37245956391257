import React, { FC, ReactNode } from "react";

import { Button } from "../Button";
import OverlayingPopup from "../OverlayingPopup";
import styles from "./index.module.css";

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  onSubmit: () => void;
  children: ReactNode;
};

const SubmitActionPopup: FC<Props> = ({ isOpen, children, onSubmit, setIsOpen }) => {
  return (
    <OverlayingPopup isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <div className={styles.container}>
        <div>{children}</div>
        <div className={styles.buttons}>
          <Button
            className={styles.cancelButton}
            type="button"
            variant="outlined"
            size="small"
            onClick={() => setIsOpen(false)}
          >
            Нет
          </Button>
          <Button
            className={styles.submitButton}
            type="button"
            variant="primary"
            size="small"
            onClick={() => {
              onSubmit();
              setIsOpen(false);
            }}
          >
            Удалить
          </Button>
        </div>
      </div>
    </OverlayingPopup>
  );
};

export default SubmitActionPopup;
