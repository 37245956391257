import React, { FC, memo } from "react";

import { DetailQuestionnaire } from "../../../lib/models/questionnaire";
import Template from "../Template";

type Props = {
  templates: DetailQuestionnaire[];
  setSelectedTemplate: (template: DetailQuestionnaire) => void;
};

const TemplatesList: FC<Props> = ({ templates, setSelectedTemplate }) => {
  return (
    <div>
      {templates.map((template) => (
        <Template setSelectedTemplate={setSelectedTemplate} key={template.id} template={template} />
      ))}
    </div>
  );
};

export default memo(TemplatesList);
