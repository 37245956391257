import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import disclaimer from "../../images/disclaimer.svg";
import { useChangeMainTestIsViewed } from "../../queries/tests";
import { usePatientStore } from "../../store/usePatientStore";
import { Button } from "../../UI/Button";
import styles from "./index.module.css";

interface DenialResponsibilityProps {
  openModal: boolean;
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const DenialResponsibility = (props: DenialResponsibilityProps) => {
  const { openModal, closeModal } = props;
  const { mainTest } = usePatientStore((state) => state);
  const { mutateAsync } = useChangeMainTestIsViewed();
  const acceptedAgreement = async () => {
    if (mainTest) {
      await mutateAsync({ testId: mainTest.id, is_viewed: true });
    }
    closeModal(false);
  };
  useEffect(() => {
    if (openModal) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "inherit";
  }, [openModal]);
  return (
    <div>
      <div className={`${styles.modal} ${openModal ? styles.modalShow : ""}`}>
        <div className={styles.modalContent}>
          <div className={styles.imgBlock}>
            <img src={disclaimer} />
          </div>
          <div>
            <div className={styles.modalTitle}>Предупреждение</div>
            <div className={styles.body}>
              <span>
                Услуги компании, предоставляемые в рамках исследования микробиоты, носят информационный,
                научно-исследовательский характер. Услуги не являются медицинскими и не имеют самостоятельного
                законченного значения в вопросах профилактики, диагностики, лечения заболеваний, медицинской
                реабилитации.
              </span>
              <span>
                Вся информация, предоставляемая по результатам тестирования, предназначена только для образовательных и
                научных целей.
              </span>
            </div>
            <Link to={"/report"} style={{ textDecoration: "none" }}>
              <Button
                className={styles.closeButton}
                onClick={acceptedAgreement}
                variant={"outlined"}
                size={"large"}
                type={"button"}
              >
                Принимаю
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DenialResponsibility;
