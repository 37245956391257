import React, { FC, memo, useEffect, useState } from "react";

import Chevron from "../../../images/chevron";
import useWindowSize from "../../../lib/hooks/useWindowSize";
import { DetailQuestionnaire, DetailSection } from "../../../lib/models/questionnaire";
import { Button } from "../../../UI/Button";
import { CircularProgressBar } from "../../../UI/Charts";
import styles from "./index.module.css";

type Props = {
  templates: DetailQuestionnaire[];
  activeBlock: number;
  selectedTemplate: DetailQuestionnaire;
  setSelectedTemplate: (template: DetailQuestionnaire) => void;
};

const calculateQuestionnaireProgress = (questionnaire: DetailQuestionnaire) => {
  return Math.round(questionnaire.sections.reduce((acc, e) => acc + e.progress, 0) / questionnaire.sections.length);
};

const calculateTotalProgress = (questionnaires: DetailQuestionnaire[]) => {
  return Math.round(
    questionnaires.reduce((acc, e) => acc + calculateQuestionnaireProgress(e), 0) / questionnaires.length
  );
};

const ProgressAside: FC<Props> = ({ templates, selectedTemplate }) => {
  const [isShow, setIsShow] = useState(true);

  const { width } = useWindowSize();
  const mobile = 988;

  useEffect(() => {
    if (isShow && width < mobile) {
      document.body.style.overflow = "hidden";
    } else document.body.style.overflow = "inherit";
  }, [isShow]);

  const scrollToTemplate = (template: DetailQuestionnaire) => {
    setTimeout(() => {
      setIsShow(false);
      const element = document.getElementById(`template${template.id}`);
      element?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 100);
  };

  const scrollToSection = (template: DetailQuestionnaire, section: DetailSection) => {
    setTimeout(() => {
      setIsShow(false);
      const indexSections = template.sections.findIndex((e) => e.name === section.name);
      const element = document.getElementById(`section${indexSections}template${template.id}`);
      element?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 100);
  };

  return (
    <div className={`${styles.container} ${isShow ? styles.stickyTop : ""}`}>
      <div>
        <div className={styles.header} onClick={() => setIsShow((isShow) => !isShow)}>
          <span className={styles.title}>
            Процент заполненности всех анкет:
            <span className={styles.percent}> {calculateTotalProgress(templates)}%</span>
          </span>
          {width < mobile ? <Chevron width={22} height={14} turn={isShow ? 90 : -90} /> : ""}
        </div>
      </div>
      <div className={`${styles.scroll} ${!isShow ? styles.hidden : ""}`}>
        <div className={styles.sections}>
          {templates.map((template) => (
            <>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  scrollToTemplate(template);
                }}
                className={
                  selectedTemplate.id === template.id ? `${styles.button} ${styles.selectedButton}` : styles.button
                }
                key={template.id}
              >
                {
                  <div className={styles.buttonInner}>
                    <div className={styles.info}>
                      {template.name}
                      {template.sections.map((section, index) => (
                        <Button
                          key={section.name}
                          variant="light"
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            scrollToSection(template, section);
                          }}
                        >
                          {section.name}
                        </Button>
                      ))}
                    </div>
                    <div className={styles.chartWrapper}>
                      <CircularProgressBar percentage={calculateQuestionnaireProgress(template)} />
                    </div>
                  </div>
                }
              </button>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(ProgressAside);
