import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useScrollToTopOfPage = (disabled = false) => {
  const location = useLocation();
  useEffect(() => {
    if (!disabled) {
      window.scrollTo(0, 0);
    }
  }, [location]);
};
