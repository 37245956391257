import { FC, ReactNode } from "react";
import React from "react";

import { Logo } from "../../../images/logo";
import useWindowSize from "../../../lib/hooks/useWindowSize";
import styles from "./index.module.css";

type Props = {
  children: ReactNode;
};

export const LoginLayout: FC<Props> = ({ children }) => {
  const { width } = useWindowSize();
  return (
    <div className={styles.container}>
      {width > 988 && (
        <div className={styles.content}>
          <div className={styles.logo}>
            <Logo white />
          </div>
          <div className={styles.mainSection}>
            <h2 className={styles.header}>Забота о здоровье начинается с микробиоты</h2>
            <p className={styles.secondaryText}>
              Мы поможем разобраться, как изменить рацион и образ жизни, чтобы улучшить свое здоровье и поддерживать его
              на оптимальном уровне.
            </p>
          </div>
        </div>
      )}
      <div className={styles.form}>
        {width < 988 && (
          <div className={styles.logo}>
            <Logo />
          </div>
        )}
        {children}
      </div>
    </div>
  );
};
