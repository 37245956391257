import { http } from "../index";

export const postCourierRequest = async (date: string, time: string, testId: string | number) => {
  return await http.post(`/api/courier_requests/`, {
    appointment_time: `${date} ${time}`,
    test_id: testId,
  });
};

export const getCourierRequest = async () => {
  return await http.get("/api/courier_requests/");
};
