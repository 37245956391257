import React, { useEffect, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";

import QuestionnaireFinish from "../../features/questionnaire/QuestionnaireFinish";
import { Question } from "../../lib/models/questionnaire";
import { useFetchRequiredQuestions } from "../../queries/questionnaire";

type RequiredQuestionnaireContextType = {
  requiredQuestions: Question[];
  requiredQuestionsProgress: number;
  requiredSectionPassed: boolean;
  requiredSectionPassedHandler: () => boolean;
};

const RequiredQuestionnaireContext = React.createContext<RequiredQuestionnaireContextType | null>(null);

export const useRequiredQuestionnaire = () => {
  const context = React.useContext(RequiredQuestionnaireContext);
  if (!context) {
    throw new Error("useRequiredQuestionnaire must be used within a RequiredQuestionnaireProvider");
  }
  return context;
};

const RequiredQuestionnaireProviderWrapper = () => {
  const { data: requiredQuestions, isSuccess } = useFetchRequiredQuestions();
  const requiredQuestionsProgress = useMemo(
    () => requiredQuestions.filter((e) => e.answer).length / requiredQuestions.length,
    [requiredQuestions]
  );

  if (isSuccess) {
    return <RequiredQuestionnaireProvider requiredQuestions={requiredQuestions} progress={requiredQuestionsProgress} />;
  }

  return <></>;
};

const RequiredQuestionnaireProvider = ({
  requiredQuestions,
  progress,
}: {
  requiredQuestions: Question[];
  progress: number;
}) => {
  const [requiredSectionPassed, setRequiredSectionPassed] = useState<boolean>(
    requiredQuestions.length ? progress === 1 : true
  );
  const [finishPopupIsOpen, setFinishPopupIsOpen] = useState<boolean>(false);

  const requiredSectionPassedHandler = () => {
    setRequiredSectionPassed(progress === 1);
    if (progress === 1) {
      setFinishPopupIsOpen(true);
    }
    return progress === 1;
  };

  useEffect(() => {
    if (progress < 1) {
      setRequiredSectionPassed(requiredQuestions.length ? progress === 1 : true);
    }
  }, [progress]);

  return (
    <RequiredQuestionnaireContext.Provider
      value={{
        requiredQuestions: requiredQuestions,
        requiredQuestionsProgress: progress,
        requiredSectionPassed,
        requiredSectionPassedHandler,
      }}
    >
      <Outlet />
      <QuestionnaireFinish
        isOpen={finishPopupIsOpen}
        onClose={() => {
          setFinishPopupIsOpen(false);
        }}
        sectionName={"Обязательные вопросы"}
      />
    </RequiredQuestionnaireContext.Provider>
  );
};

export default RequiredQuestionnaireProviderWrapper;
