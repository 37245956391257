import React from "react";

interface propsReportReady {
  className: string;
  color?: string;
  isCheck?: boolean;
}
const ReportReady = (props: propsReportReady) => {
  const { className, color = "#6100FF", isCheck = false } = props;

  return (
    <svg
      className={className}
      width="64"
      height="65"
      viewBox="0 0 64 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1.5" y="2" width="61" height="61" rx="30.5" fill={isCheck ? "#6100FF" : "white"} />
      <mask id="mask0_11303_31687" maskUnits="userSpaceOnUse" x="16" y="16" width="32" height="33">
        <rect x="16" y="16.5" width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_11303_31687)">
        <path
          d="M30.1333 37.9026L39.5333 28.5026L37.6667 26.6359L30.1333 34.1693L26.3333 30.3693L24.4667 32.2359L30.1333 37.9026ZM22.6667 44.5026C21.9333 44.5026 21.3056 44.2415 20.7833 43.7193C20.2611 43.197 20 42.5693 20 41.8359V23.1693C20 22.4359 20.2611 21.8082 20.7833 21.2859C21.3056 20.7637 21.9333 20.5026 22.6667 20.5026H28.2667C28.5556 19.7026 29.0389 19.0582 29.7167 18.5693C30.3944 18.0804 31.1556 17.8359 32 17.8359C32.8444 17.8359 33.6056 18.0804 34.2833 18.5693C34.9611 19.0582 35.4444 19.7026 35.7333 20.5026H41.3333C42.0667 20.5026 42.6944 20.7637 43.2167 21.2859C43.7389 21.8082 44 22.4359 44 23.1693V41.8359C44 42.5693 43.7389 43.197 43.2167 43.7193C42.6944 44.2415 42.0667 44.5026 41.3333 44.5026H22.6667ZM22.6667 41.8359H41.3333V23.1693H22.6667V41.8359ZM32 22.1693C32.2889 22.1693 32.5278 22.0748 32.7167 21.8859C32.9056 21.697 33 21.4582 33 21.1693C33 20.8804 32.9056 20.6415 32.7167 20.4526C32.5278 20.2637 32.2889 20.1693 32 20.1693C31.7111 20.1693 31.4722 20.2637 31.2833 20.4526C31.0944 20.6415 31 20.8804 31 21.1693C31 21.4582 31.0944 21.697 31.2833 21.8859C31.4722 22.0748 31.7111 22.1693 32 22.1693Z"
          fill={isCheck ? "white" : color}
        />
      </g>
      <rect x="1.5" y="2" width="61" height="61" rx="30.5" stroke={color} strokeWidth="3" />
    </svg>
  );
};

export default ReportReady;
