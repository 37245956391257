import React, { useEffect, useState } from "react";

import DenialResponsibility from "../../components/DenialResponsibility";
import ReportProgress from "../../components/ReportProgress";
import { TestStatus } from "../../components/TestStatus";
import { usePatientStore } from "../../store/usePatientStore";
import styles from "./index.module.css";

type statusStep = {
  step: number;
};
const statusSwitcher = (status: string): statusStep => {
  switch (status) {
    case "created":
      return { step: 0 };
    case "paid":
      return { step: 1 };
    case "delivery_to_user": // 0
      return { step: 2 };
    case "delivered_to_user": //1
      return { step: 3 };
    case "courier_requested": //2
      return { step: 4 };
    case "delivery_to_lab": //3
      return { step: 5 };
    case "delivered_to_lab":
      return { step: 6 };
    case "uploaded": //4
      return { step: 7 };
    case "processed":
      return { step: 8 };
    case "deciphered":
      return { step: 9 };
    case "correction":
      return { step: 9 };
    case "review":
      return { step: 10 };
    case "ready":
      return { step: 11 }; //5
    default:
      return { step: 0 };
  }
};

const ReportProgressPage = () => {
  const { mainTest } = usePatientStore((state) => state);

  const [retakeTest, setRetakeTest] = useState(mainTest ? mainTest.date : "");
  const [step, setStep] = useState<number>(0);
  const [selectReport, setSelectReport] = useState<number>(0);
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    setStep(statusSwitcher(mainTest ? mainTest.status : "created").step);
    setSelectReport(statusSwitcher(mainTest ? mainTest.status : "created").step);
  }, [mainTest]);

  return (
    <div className={styles.container}>
      <div className={styles.shadow} />
      <ReportProgress
        partner={!!mainTest?.partner}
        setSelectReport={setSelectReport}
        retakeTest={retakeTest}
        selectReport={selectReport}
        step={step}
      />
      <TestStatus
        setOpenModal={setOpenModal}
        partner={!!mainTest?.partner}
        retakeTest={retakeTest}
        statusSwitcher={statusSwitcher}
        testsStatus={selectReport}
      />
      <DenialResponsibility openModal={openModal} closeModal={() => setOpenModal(false)} />
    </div>
  );
};

export default ReportProgressPage;
