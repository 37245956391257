import { FC, useEffect } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { useAuth } from "../../../layouts/AuthProvider/AuthProvider";
import { Button } from "../../../UI/Button";
import Checkbox from "../../../UI/Checkbox/index.";
import { Input } from "../../../UI/Input";
import styles from "./index.module.css";

type LoginFormType = {
  email: string;
  password: string;
};

export const LoginForm: FC = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    formState: { errors, isValid, isSubmitting },
  } = useForm<LoginFormType>({ mode: "onChange" });

  const [searchParams] = useSearchParams();

  const onSubmit = async (data: LoginFormType) => {
    try {
      await login(data);
      const redirectUrl = searchParams.get("redirect_url");
      searchParams.delete("redirect_url");
      const next = redirectUrl ? redirectUrl + "?" + searchParams.toString() : "/";
      navigate(next);
    } catch (e: any) {
      console.log(e);
      if (e.response.data.detail === "No active account found with the given credentials") {
        setError("root", { type: "value", message: "Неверный логин или пароль" });
      } else setError("root", { type: "value", message: e.response.data.detail });
    }
  };

  useEffect(() => {
    const subscription = watch(() => {
      clearErrors();
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <div className={styles.container}>
      <h3 className={styles.header}>Войти в аккаунт</h3>
      <p className={styles.secondaryText}>Войдите в аккаунт или зарегистрируйтесь в Novabiom в пару кликов</p>

      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <Input
          marginBottom={12}
          type="text"
          placeholder="Email адрес"
          error={!!errors.root?.message || errors.email?.message}
          register={register("email", { required: "Обязательное поле" })}
          className={styles.input}
        />
        <Input
          type="password"
          placeholder="Пароль"
          register={register("password", { required: "Обязательное поле" })}
          className={styles.input}
          error={errors.root?.message || errors.password?.message}
        />
        <div className={styles.loginSettings}>
          <label className={styles.checkboxLabel}>
            <Checkbox variant="checkbox">
              <div className={styles.checkbox}>Не выходить из аккаунта</div>
            </Checkbox>
          </label>
          <Link to="/forgot_password" className={styles.forgotPassword}>
            Забыли пароль?
          </Link>
        </div>

        <Button variant="primary" size="large" type="submit" disabled={!isValid || isSubmitting}>
          Войти
        </Button>

        <div className={styles.signIn}>
          <p className={styles.secondaryText}>Ещё нет аккаунта?</p>
          <Link to="/sign" className={styles.signText}>
            Зарегистрируйтесь
          </Link>
        </div>
      </form>
    </div>
  );
};
