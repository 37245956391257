import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";

import AsideProvider from "../../generic/providers/AsideProvider";
import styles from "./index.module.css";

const OrgUserLayout = () => {
  return (
    <AsideProvider>
      <div className={styles.container}>
        <Suspense fallback={<div />}>
          <Outlet />
        </Suspense>
      </div>
    </AsideProvider>
  );
};

export default OrgUserLayout;
