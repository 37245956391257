import React, { useRef } from "react";

import DocReportPreview, { DocReportPreviewRef } from "../../../components/DocReportPreview/DocReportPreview";
import { useFetchMainTestResult } from "../../../queries/tests";
import DocInterpretationDropdownList from "../DocInterpretationDropdownList/DocInterpretationDropdownList";
import styles from "./InterpretationWidget.module.css";

const InterpretationWidget = () => {
  const { data } = useFetchMainTestResult();
  const docPreviewRef = useRef<DocReportPreviewRef | null>(null);

  return (
    <div className={styles.container}>
      <div className={styles.dropdownListContainer}>
        <div className={styles.dropdownListWrapper}>
          <DocInterpretationDropdownList
            onUpdateButton={() => {
              docPreviewRef.current?.refresh();
            }}
          />
        </div>
      </div>
      <div className={styles.dropdownListContainer}>
        <div className={styles.dropdownListWrapper}>
          {data && <DocReportPreview ref={docPreviewRef} testResult={data} />}
        </div>
      </div>
    </div>
  );
};

export default InterpretationWidget;
