import React, { FC } from "react";

import { OrgUser } from "../../lib/models/OrgUser";
import styles from "./OrgPatientsTable.module.css";
import OrgPatientTableRow from "./OrgPatientTableRow";

type Props = {
  patients: OrgUser[];
};

const OrgPatientsTable: FC<Props> = ({ patients }) => {
  return (
    <table className={styles.container}>
      <thead>
        <tr>
          <th>Дата</th>
          <th>ID образца</th>
          <th>ФИО пациента</th>
          <th>Отчет</th>
          <th>Действие</th>
        </tr>
      </thead>
      <tbody>
        {patients.map((e, index) => (
          <OrgPatientTableRow orgUser={e} key={index} />
        ))}
      </tbody>
    </table>
  );
};

export default OrgPatientsTable;
