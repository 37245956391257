import React from "react";

import { useUser } from "../../lib/hooks/useUser";
import { usePatientStore } from "../../store/usePatientStore";
import MotherAndChildPatientAsideHeaderVariant from "./asideHeaderVariants/MotherAndChildPatientAsideHeaderVariant/MotherAndChildPatientAsideHeaderVariant";
import NovabiomAsideHeaderVariant from "./asideHeaderVariants/NovabiomAsideHeaderVariant/NovabiomAsideHeaderVariant";
import NovabiomOrgAsideHeaderVariant from "./asideHeaderVariants/NovabiomOrgAsideHeaderVariant/NovabiomOrgAsideHeaderVariant";
import NovabiomPatientAsideHeaderVariant from "./asideHeaderVariants/NovabiomPatientAsideHeaderVariant/NovabiomPatientAsideHeaderVariant";

const AsideHeader = () => {
  const { user } = useUser();
  const mainTest = usePatientStore((state) => state.mainTest);

  const render = () => {
    if (user.type === "patient") {
      if (mainTest?.partner?.startsWith("mother_and_child")) {
        return <MotherAndChildPatientAsideHeaderVariant />;
      }
      return <NovabiomPatientAsideHeaderVariant />;
    }
    if (user.type === "doctor") {
      return <NovabiomAsideHeaderVariant />;
    }
    if (user.type === "org") {
      return <NovabiomOrgAsideHeaderVariant />;
    }
  };

  return <>{render()}</>;
};

export default AsideHeader;
