import React, { FC, useEffect } from "react";

import { Section } from "../../../lib/models/questionnaire";
import { useFetchSectionAvailableQuestions } from "../../../queries/questionnaire";
import SectionPassingProvider from "./SectionPassingProvider";

type Props = {
  section: Section;
  nextSection?: Section;
};

const SectionQuestionsLoader: FC<Props> = (props) => {
  const { data: questions, refetch } = useFetchSectionAvailableQuestions(props.section.id);

  useEffect(() => {
    refetch();
  }, []);

  if (!questions.at(0)) {
    return null;
  }
  return <SectionPassingProvider {...props} questions={questions} />;
};

export default SectionQuestionsLoader;
