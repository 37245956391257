import React from "react";
import { Navigate, Route } from "react-router-dom";

import UserLayout from "../layouts/UserLayout";
import { OrgPatientUrls, OrgUrls } from "../lib/routes";
import { NotFound } from "../pages/NotFound";
import OrgPatientPage from "../pages/OrgPatientPage";
import OrgPatientsPage from "../pages/OrgPatientsPage";
import ProfilePage from "../pages/ProfilePage";

const OrgRoutes = () => {
  return (
    <Route>
      <Route element={<UserLayout />}>
        <Route index element={<Navigate to={OrgUrls.ORG_PATIENTS} />} />
        <Route path={OrgUrls.PROFILE} element={<ProfilePage profileType="organization" />}></Route>
        <Route path={OrgUrls.ORG_PATIENTS} element={<OrgPatientsPage />} />
        <Route path={String(OrgPatientUrls.REPORT)} element={<OrgPatientPage />} />
        <Route path={String(OrgPatientUrls.QUESTIONNAIRES)} element={<OrgPatientPage />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Route>
  );
};

export default OrgRoutes;
