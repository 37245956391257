export const INTERPRETATION_LOCALS: Record<string, string> = {
  consultation_date: "Дата консультации",
  consultant: "Консультант",
  microbiota_index: "Индекс разнообразия микробиоты",
  diseases: "Риски заболеваний",
  pro_inflammatory_bacteria: "Провоспалительные бактерии",
  anti_inflammatory_bacteria: "Противовоспалительные бактерии",
  probiotic_bacteria: "Бактерии с пробиотическим потенциалом",
  commensal_bacteria: "Комменсальные бактерии",
  scfa_producing_bacteria: "Бактерии-продуценты КЦЖК",
  acetic_acid: "Уксусная кислота (ацетат)",
  propionic_acid: "Пропионовая кислота (пропионат)",
  butyric_acid: "Масляная кислота (бутират)",
  appetite: "Регуляция аппетита",
  vitamin_metabolism: "Бактерии, участвующие в метаболизме витаминов",
  probiotics: "Пробиотики для коррекции микробиоты",
  biologically_active_additives: "Биологически активные добавки для коррекции микробиоты",
  nutrition: "Рекомендации по питанию",
  additional_tests: "Повторная сдача теста и дополнительные анализы",
};
