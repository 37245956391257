import React, { lazy } from "react";
import { Navigate, Route } from "react-router-dom";

import ScrollTopLayout from "../components/ScrollTopLayout";
import UserLayout from "../layouts/UserLayout";
import ReviewPatientProvider from "../lib/ReviewPatientProvider";
import { DoctorUrls, PatientUrls } from "../lib/routes";
import InterpretationPage from "../pages/InterpretationPage";
import { NotFound } from "../pages/NotFound";
import QuestionnaireReviewPage from "../pages/QuestionnaireReviewPage";

const ConsultationConclusion = lazy(() => import("../pages/ConsultationConclusion"));
const ConsultationsPage = lazy(() => import("../pages/ConsultationsPage"));
const DoctorConclusionPage = lazy(() => import("../pages/DoctorConclusionPage"));
const PatientsPage = lazy(() => import("../pages/PatientsPage"));
const ProfilePage = lazy(() => import("../pages/ProfilePage"));
const ReportPage = lazy(() => import("../pages/ReportPage"));

const DoctorRoutes = () => {
  return (
    <Route element={<UserLayout />}>
      <Route path="/" element={<Navigate to={DoctorUrls.CONSULTATIONS} />}></Route>
      <Route element={<ScrollTopLayout />}>
        <Route path={DoctorUrls.CONSULTATIONS} element={<ConsultationsPage />}></Route>
        <Route path={DoctorUrls.CONSULTATIONS_CONCLUSION} element={<ConsultationConclusion />} />
        <Route path={DoctorUrls.PATIENTS} element={<PatientsPage />}></Route>
        <Route path={DoctorUrls.PROFILE} element={<ProfilePage />}></Route>
        <Route element={<ReviewPatientProvider />}>
          <Route path={DoctorUrls.DOCTOR_CONCLUSION} element={<DoctorConclusionPage />} />
          <Route path={PatientUrls.QUESTIONNAIRES} element={<QuestionnaireReviewPage />} />
          <Route path={DoctorUrls.INTERPRETATION} element={<InterpretationPage />} />
          <Route path={PatientUrls.REPORT} element={<ReportPage />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </Route>
  );
};

export default DoctorRoutes;
