import React from "react";

import InterpretationWidget from "../../features/interpretation/InterpretationWidget/InterpretationWidget";
import styles from "./index.module.css";

const InterpretationPage = () => {
  return (
    <div className={styles.container}>
      <InterpretationWidget />
    </div>
  );
};

export default InterpretationPage;
