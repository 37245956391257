import { useMutation, useQuery, useQueryClient, UseQueryOptions } from "react-query";

import { getInterpretation, patchInterpretation } from "../../API/DoctorService";
import { Interpretation, InterpretationKeys } from "../../lib/models/test";
import { INTERPRETATION_LOCALS } from "../../lib/models/test/interpretationLocals";

export type InterpretationFiled = {
  key: InterpretationKeys;
  name: string;
  value: string;
};

export const useFetchInterpretation = (
  testId: number,
  args?: { options?: UseQueryOptions<InterpretationFiled[], Error> }
) => {
  const { ...rest } = useQuery<InterpretationFiled[], Error>(
    ["tests", "test", testId, "interpretation"],
    async () => {
      const resultInterpretation = await getInterpretation(testId);

      const result: InterpretationFiled[] = Object.entries(resultInterpretation)
        .map(([key, value]) => {
          const locale = INTERPRETATION_LOCALS[key as InterpretationKeys];
          return { name: locale, value: value || "", key };
        })
        .filter((e) => !["consultation_date", "consultant"].includes(e.key));

      return result;
    },
    {
      ...args?.options,
    }
  );
  return { ...rest, data: rest.data || [] };
};

type PatchInterpretationParams = {
  testId: number;
  interpretationFields: InterpretationFiled[];
};

export const usePatchInterpretation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ testId, interpretationFields }: PatchInterpretationParams) => {
      const result: Omit<Interpretation, "consultant" | "consultation_date"> = {};
      interpretationFields.forEach(({ key, value }) => {
        result[key] = value;
      });
      return patchInterpretation(testId, result);
    },
    {
      onSettled: (data, error, variables) => queryClient.invalidateQueries(["tests", "test", variables.testId]),
    }
  );
};
