import React, { ButtonHTMLAttributes, FC } from "react";

import { ReactComponent as ToggleIcon } from "../../images/arrows/navigation.svg";
import cn from "../../lib/utils/cn";
import styles from "./ToggleDropdown.module.css";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  initAngle?: number;
  activeAngle?: number;
}
const ToggleDropdown: FC<Props> = (props) => {
  const { isOpen, setIsOpen, activeAngle = 0, initAngle = -180, onClick } = props;
  return (
    <button
      {...props}
      onClick={(e) => {
        setIsOpen(!isOpen);
        onClick && onClick(e);
      }}
      className={cn(styles.container, props.className)}
    >
      <ToggleIcon className={cn(styles.icon)} style={{ transform: `rotate(${isOpen ? activeAngle : initAngle}deg)` }} />
    </button>
  );
};

export default ToggleDropdown;
