import React, { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { CreateOrgUser } from "../../lib/models/OrgUser";
import { useCreateOrgUser } from "../../queries/org/users";
import { Button } from "../../UI/Button";
import { Input } from "../../UI/Input";
import styles from "./AddOrgPatientSection.module.css";

const AddOrgPatientSection = () => {
  const {
    register,
    handleSubmit,
    reset: resetForm,
    watch,
    formState: { errors },
  } = useForm<CreateOrgUser>();

  const { mutateAsync, error, reset } = useCreateOrgUser({ onSuccess: () => resetForm() });
  const watchTestID = watch("test_id");

  const onSubmit: SubmitHandler<CreateOrgUser> = async (data) => {
    await mutateAsync(data);
  };

  useEffect(() => {
    // Очистить ошибки react-query при изменении test_id в форме
    reset();
  }, [watchTestID]);

  return (
    <div className={styles.container}>
      <h2>Добавить клиента</h2>
      <form className={styles.addForm} onSubmit={handleSubmit(onSubmit)}>
        <div>
          <p>
            ID образца <span>*</span>
          </p>
          <Input
            errorClassName={styles.error}
            error={(errors.test_id && "Это поле обязательное") || (error as Error)?.message}
            className={styles.formInput}
            placeholder="0224-HFO-493803"
            register={register("test_id", { required: true })}
          />
        </div>
        <div>
          <p>Фамилия</p>
          <Input
            errorClassName={styles.error}
            className={styles.formInput}
            placeholder="Иванов"
            register={register("last_name")}
          />
        </div>
        <div>
          <p>Имя</p>
          <Input className={styles.formInput} placeholder="Иван" register={register("first_name")} />
        </div>
        <div>
          <p>Отчество</p>
          <Input className={styles.formInput} placeholder="Иванович" register={register("middle_name")} />
        </div>
        <Button variant="primary" size="small" className={styles.addButton} type="submit">
          Добавить
        </Button>
      </form>
    </div>
  );
};

export default AddOrgPatientSection;
